
import { Options, Vue } from 'vue-class-component';
import IconButton from '../UI/IconButton.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Tooltip from '../UI/Tooltip.vue';
import { tooltip } from './ThumbModal.vue';
import { Media } from '@/interfaces/Media';

@Options({
    components: {
        IconButton,
        BaseIcon,
        Tooltip,
    },
    props: {
        tooltip: {
            type: Object,
            required: true,
        },
        media: {
            type: Object,
            required: true,
        },
    },
    emits: ['downloadDefault', 'downloadSelectedSize']
})
export default class DownloadHelper extends Vue {
  media!: Media;
  tooltip!: tooltip;
  enableDownloadSizes = false;


  downloadDefault(): void {
      this.$emit('downloadDefault');
  }

  downloadSelectedSize(id: number, key: string): void {
      this.enableDownloadSizes = false;
      this.$emit('downloadSelectedSize', id, key);
  }

  toggleEnableDownloadSizes(): void {
      this.enableDownloadSizes = !this.enableDownloadSizes;
      const downloadSizes: HTMLDivElement = this.$refs.downloadSizes as HTMLDivElement;
      downloadSizes.addEventListener('mouseleave', function() {
          setTimeout(() => {
              downloadSizes.style.display = 'none';
          }, 1000);
      });
  }
}
