<template>
  <path
    d="M0,7.5 C0,10.1794921 1.42949188,12.6554447 3.74999997,13.9951907 C6.07050806,15.3349367 8.92949194,15.3349367 11.25,13.9951907 C13.5705081,12.6554447 15,10.1794921 15,7.5 C15,4.82050816 13.5705081,2.34455548 11.25,1.00480949 C8.92949194,-0.334936497 6.07050806,-0.334936497 3.74999997,1.00480949 C1.42949188,2.34455548 0,4.82050816 0,7.5 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <polyline
    id="Path"
    stroke="#000000"
    points="7.50065217 3.91304359 7.50065217 7.50000011 11.4136957 11.0869566"
  ></polyline>
</template>
;
