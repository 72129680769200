
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import 'vue-next-select/dist/index.min.css';
import CollectionMenu from '@/components/Collection/CollectionMenu.vue';
import userCollection from '@/services/UserCollectionService';
import CollectionService from '@/services/collectionService';
import ThumbModal from '@/components/global/ThumbModal/ThumbModal.vue';
import CollectionEditModal from '@/components/Collection/CollectionEditModal.vue';
import CollectionMoveFileModal from '@/components/Collection/CollectionMoveFileModal.vue';
import ThumbCollection from '@/components/global/ThumbCollection/ThumbCollection.vue';
import { collection } from '@/interfaces/collection';

interface breadcrumb {
  link: string;
  name: string;
}

@Options({
    props: {
        isPublic: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: {
        CollectionMenu,
        ThumbCollection,
        ThumbModal,
        CollectionEditModal,
        CollectionMoveFileModal,
    },
})
export default class CollectionView extends Vue {
  isPublic = false;
  collectionOptions: Array<collection> = [];
  targetCollectionID = 0;
  errMessage = '';
  showError = false;
  breadcrumbs: Array<breadcrumb> = [];
  imagesLoaded = false;
  settingsEnabled = false;
  CollectionEditModalEnabled = false;
  collection: collection = {
      id: 0,
      user_id: 0,
      name: '',
      is_locked: false,
      created_at: '',
      updated_at: '',
      media: {
          videos: [],
          images: [],
      },
  };

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newRoute: RouteLocationNormalizedLoaded): void {
      this.isPublic = newRoute.path.includes('isPublic');
      this.imagesLoaded = false;
      this.generateContent();
  }

  async mounted(): Promise<void> {
      const route = useRoute();
      this.isPublic = route.path.includes('isPublic');
      await this.generateContent();
      userCollection.view().then((res) => {
          this.collectionOptions = res.data;
      });
  }

  async generateContent(): Promise<void> {
      if (this.isPublic) {
          CollectionService.get(Number(this.$route.params.id)).then((res) => {
              this.collection = res.data as collection;
              this.generateBreadcrumbs(this.isPublic, this.collection);
              this.imagesLoaded = true;
          });
      } else {
          userCollection.get(Number(this.$route.params.id)).then((res) => {
              this.collection = res.data as collection;
              this.generateBreadcrumbs(this.isPublic, this.collection);
              this.imagesLoaded = true;
          });
      }
  }

  toggleCollectionEditModal(): void {
      this.toggleSettings();
      this.CollectionEditModalEnabled = !this.CollectionEditModalEnabled;
  }

  generateBreadcrumbs(public_collection: boolean, resource: collection): void {
      this.breadcrumbs = [];
      if (public_collection) {
          this.breadcrumbs.push(
              {
                  link: '/collections/public',
                  name: 'Curated Collections',
              },
              {
                  link: '/collections/public/' + resource.id,
                  name: resource.name,
              }
          );
      } else {
          this.breadcrumbs.push(
              {
                  link: '/collections',
                  name: 'My Collections',
              },
              {
                  link: '/collections/' + resource.id,
                  name: resource.name,
              }
          );
      }
  }

  removeFile(id: number): void {
      userCollection
          .delete(Number(this.$route.params.id), id)
          .then((res) => {
              console.log(res.status);
          });
  }

  toggleSettings(): void {
      this.settingsEnabled = !this.settingsEnabled;
  }

  moveFile(id: number): void {
      if (this.targetCollectionID != 0) {
          userCollection.view().then((res) => {
              this.collectionOptions = res.data as Array<collection>;
              console.log(
                  'I am supposed to move: ' + id + ' to ' + this.targetCollectionID
              );
          });
      } else {
          console.log('Please select a destination for the file');
      }
  }
}
