<template>
  <path
    d="M0.000 7.000 A7.000 7.000 0 1 0 14.000 7.000 A7.000 7.000 0 1 0 0.000 7.000 Z"
    fill="#ffffff"
    stroke="grey"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1"
  ></path>
</template>
;
