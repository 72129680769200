
import { Options, Vue } from 'vue-class-component';
import BaseView from './BaseView/BaseView.vue';
import SearchQuickSearches from '@/components/Search/SearchQuickSearches.vue';

@Options({
    components: {
        BaseView,
        SearchQuickSearches,
    },
})
export default class Home extends Vue {}
