
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';

@Options({
    props: {
        name: {
            type: String,
            required: true,
        },
        colorHint: {
            type: String,
            required: false,
            default: '',
        },
    },
    components: {
        BaseIcon,
    },
})
export default class SelectedFilterLabel extends Vue {}
