import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b299dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "arrowIcon" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "content-text content-wrapper" }
const _hoisted_6 = { class: "list-item-icon" }
const _hoisted_7 = { class: "list-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_accordion = _resolveComponent("accordion")!

  return (_openBlock(), _createBlock(_component_accordion, { class: "accordion-menu-list" }, {
    "accordion-trigger": _withCtx(() => [
      _createElementVNode("li", {
        class: "top-item",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccordionIcon && _ctx.toggleAccordionIcon(...args)))
      }, [
        _createElementVNode("div", null, [
          (_ctx.leftIcon)
            ? (_openBlock(), _createBlock(_component_base_icon, {
                key: 0,
                class: "leftI",
                height: "28",
                "icon-name": "leftIcon",
                viewBox: "-1 -2 24 24",
                width: "28"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent('icon-' + _ctx.leftIcon)))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              id: _ctx.id + 'AccordionInactive',
              ref: _ctx.id + 'AccordionInactive',
              class: "accordion-icon-right active"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fa', 'chevron-right'],
                class: "icon arrow alt"
              })
            ], 8, _hoisted_3),
            _createElementVNode("div", {
              id: _ctx.id + 'AccordionActive',
              ref: _ctx.id + 'AccordionActive',
              class: "accordion-icon-down"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fa', 'chevron-down'],
                class: "icon arrow alt"
              })
            ], 8, _hoisted_4)
          ])
        ])
      ])
    ]),
    "accordion-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.link
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: item.link
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_base_icon, {
                    viewBox: item.viewBox ? item.viewBox : _ctx.defaultViewBox,
                    "icon-name": item.icon
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(item.iconTag)))
                    ]),
                    _: 2
                  }, 1032, ["viewBox", "icon-name"])
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(item.text), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}