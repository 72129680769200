
import { Vue, Options } from 'vue-class-component';
import SearchField from '@/components/global/SearchField/SearchField.vue';
import MenuComponent from '../MenuComponent/MenuComponent.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import PageTitle from '@/components/global/UI/PageTitle.vue';

@Options({
    components: {
        SearchField,
        MenuComponent,
        BaseIcon,
        PageTitle
    },
    emits: ['toggle-background-overlay'],
})
export default class TopBar extends Vue {
  displayMobileMenu = false;

  toggleMobileMenu(): void {
      this.displayMobileMenu = !this.displayMobileMenu;

      const menuOpen = this.$refs.menuOpen as HTMLDivElement;
      const menuClose = this.$refs.menuClose as HTMLDivElement;

      if (this.displayMobileMenu) {
          menuClose.style.display = 'block';
          menuOpen.style.display = 'none';
          this.$emit('toggle-background-overlay', true);
      } else {
          menuClose.style.display = 'none';
          menuOpen.style.display = 'block';
          this.$emit('toggle-background-overlay', false);
      }
  }

  resetMenuToggle(): void {
      const menuOpen = this.$refs.menuOpen as HTMLDivElement;
      const menuClose = this.$refs.menuClose as HTMLDivElement;
      menuClose.style.display = 'none';
      menuOpen.style.display = 'block';
      this.$emit('toggle-background-overlay', false);
  }
}
