import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d1f8a70"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "dropZone",
  class: "drop-zone"
}
const _hoisted_2 = {
  ref: "dropZonePrompt",
  class: "drop-zone__prompt"
}
const _hoisted_3 = {
  ref: "dropZoneThumb",
  class: "drop-zone__thumb"
}
const _hoisted_4 = {
  ref: "dropZoneInput",
  class: "drop-zone__input",
  name: "myFile",
  type: "file"
}
const _hoisted_5 = {
  ref: "uploadButtonDiv",
  class: "buttonDiv"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, "Drop file here or click to upload", 512),
      _createElementVNode("div", _hoisted_3, null, 512),
      _createElementVNode("input", _hoisted_4, null, 512)
    ], 512),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "uploadButton",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args)))
      }, "Upload file")
    ], 512)
  ], 64))
}