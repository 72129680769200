
import { Options, Vue } from 'vue-class-component';
import IconButton from '../UI/IconButton.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Tooltip from '../UI/Tooltip.vue';
import { tooltip } from './ThumbModal.vue';

@Options({
    props: {
        tooltip: {
            type: Object,
            required: true,
        },
        isFavorite: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        IconButton,
        BaseIcon,
        Tooltip
    },
})
export default class IsFavoriteToggler extends Vue {
    tooltip!: tooltip;
    isFavorite!: boolean;
}
