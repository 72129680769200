import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5670eea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selection" }
const _hoisted_2 = { class: "selection-text" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "selection-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createElementVNode("span", { innerHTML: _ctx.name }, null, 8, _hoisted_3),
        (_ctx.colorHint !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "color-hint",
              style: _normalizeStyle(_ctx.colorHint)
            }, null, 4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_icon, {
        width: "12",
        height: "12",
        "stroke-width": "2.4",
        viewBox: "-0.5 -0.5 24 24",
        "icon-name": "close"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon_close)
        ]),
        _: 1
      })
    ])
  ]))
}