
import {Options, Vue} from 'vue-class-component';
import userCollection from '@/services/UserCollectionService';
import publicCollection from '@/services/collectionService';
import Masonry from 'masonry-layout';
import CollectionCreateModal from '@/components/Collection/CollectionCreateModal.vue';
import {useRoute} from 'vue-router';
import {collection} from '@/interfaces/collection';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import IconButton from '@/components/global/UI/IconButton.vue';
import BaseButton from '@/components/global/UI/BaseButton.vue';



interface breadcrumb {
    link: string;
    name: string;
}

@Options({
    components: {
        CollectionCreateModal,
        BaseIcon,
        IconButton,
        BaseButton
    },
})
export default class CollectionIndex extends Vue {
    isPublic = false;
    breadcrumbs: Array<breadcrumb> = [];
    collectionPath = '';
    masonry!: Masonry;
    collections!: Array<collection>;
    CollectionCreateModalEnabled = false;

    mounted(): void {
        const route = useRoute();
        this.isPublic = route.path.includes('isPublic');
        this.collectionPath = this.isPublic === true ? '/collections/public/' : '/collections/';

        if (this.isPublic) {
            publicCollection.view().then((res) => {
                this.collections = res.data as Array<collection>;
                this.createGrid();
            });
        } else {
            userCollection.view().then((res) => {
                this.collections = res.data;
                this.createGrid();
            });
        }

        this.generateBreadcrumbs(this.isPublic);
    }

    createGrid(): void {
        const grid: HTMLDivElement = this.$refs.masonryGrid as HTMLDivElement;
        if (grid) {
            this.collections.forEach((collection) => {
                const item = document.createElement('div');
                item.className = 'collection-item';

                if (collection && collection.preview_media && collection.preview_media.length > 0) {
                    item.innerHTML =
                        '' +
                        '<div class="collection-item-wrapper">' +
                        '<div class="preview-img-large"><a href="' + this.collectionPath +
                        collection.id +
                        '"><img src="' +
                        collection.preview_media[0].thumbnail_url +
                        '"></a></div>' +
                        '<div class="collection-item-small-wrapper">' +
                        '<div class="preview-img-small"><a href="' + this.collectionPath +
                        collection.id +
                        '"><img src="' +
                        collection.preview_media[1].thumbnail_url +
                        '"></a></div>' +
                        '<div class="preview-img-small"><a href="' + this.collectionPath +
                        collection.id +
                        '"><img src="' +
                        collection.preview_media[2].thumbnail_url +
                        '"></a></div>' +
                        '<div class="preview-img-small"><a href="' + this.collectionPath +
                        collection.id +
                        '"><img src="' +
                        collection.preview_media[3].thumbnail_url +
                        '"></a></div>' +
                        '</div>' +
                        '<div class="collection-header">' +
                        collection.name +
                        '</div>' +
                        '<div class="collection-count">' +
                        collection?.media?.images.length +
                        ' photos</div>' +
                        '</div>';
                } else {
                    item.innerHTML =
                        '' +
                        '<div class="collection-item-wrapper">' +
                        '<div class="preview-img-large"><img src="' + collection.placeholder_media?.thumbnail_url + '" alt="" /></div>' +
                        '<div class="collection-item-small-wrapper">' +
                        '<div class="preview-img-small"></div>' +
                        '<div class="preview-img-small"></div>' +
                        '<div class="preview-img-small"></div>' +
                        '</div>' +
                        '<div class="collection-header">' +
                        collection.name +
                        '</div>' +
                        '<div class="collection-count">0 photos</div>' +
                        '</div>';
                }
                grid.appendChild(item);
            });
        }
    }

    generateBreadcrumbs(public_collection: boolean): void {
        if (public_collection) {
            this.breadcrumbs.push(
                {
                    link: '/collections/public',
                    name: 'Curated Collections'
                }
            );
        } else {
            this.breadcrumbs.push(
                {
                    link: '/collections',
                    name: 'My Collections'
                }
            );
        }
    }

    toggleCollectionCreateModal(): void {
        this.CollectionCreateModalEnabled = !this.CollectionCreateModalEnabled;
    }
}
