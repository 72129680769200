
import { Vue, Options } from 'vue-class-component';

    @Options({
        props: {
            title: {
                type: String,
                required: false,
            },
            isThumb: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: ['close'],
    })
export default class DokaDialog extends Vue {
        isThumb!: boolean;

}
