
import BaseView from './BaseView/BaseView.vue';
import { Vue, Options } from 'vue-class-component';
import { useRoute } from 'vue-router';
import router from '@/router';
import checkoutService from '@/services/checkoutService';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';

interface cbData {
  key: string;
  value: string;
  type: string;
}

@Options({
    components: {
        BaseView,
    },
})
export default class Payment extends Vue {
  id = '';
  chargebeeiframe!: HTMLIFrameElement;
  store!: Store<RootState>;

  mounted(): void {
      this.store = useStore();
      const route = useRoute();
      this.id =
      (('https://' +
        route.params.site +
        '/pages/v3/' +
        route.params.id) as string) + '/';

      this.chargebeeiframe = this.$refs.chargebeeiframe as HTMLIFrameElement;

      window.addEventListener('message', (event) => {
          if (
              event.origin !==
        ('https://jumpstoryint-test.chargebee.com' ||
          'https://jumpstoryint.chargebee.com')
          ) {
              return;
          }
          const eventData = event.data as cbData;
          if (eventData.key === ('cb.success' || 'cb.close')) {
              checkoutService.completeCheckout().then((res) => {
                  this.store.dispatch('completeCheckout', res).then(() => {
                      if (res.status === 200) {
                          router.push('/');
                      }
                  });
              });
          }
      });
  }
}
