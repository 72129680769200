<template>
  <polygon
    id="Path"
    stroke="#000000"
    points="9.56521739 9.56521739 20 9.56521739 20 20 9.56521739 20"
  ></polygon>
  <line
    x1="12.173913"
    y1="12.173913"
    x2="17.3913043"
    y2="12.173913"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="12.173913"
    y1="14.7826087"
    x2="17.3913043"
    y2="14.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="12.173913"
    y1="17.3913043"
    x2="14.1304348"
    y2="17.3913043"
    id="Path"
    stroke="#000000"
  ></line>
  <path
    d="M6.95652174,13.9130435 L0,13.9130435 C0.0116640577,12.919563 0.218482485,11.9380618 0.608695652,11.0243478 C1.03478261,10.1713043 2.81652174,9.58 5.05304348,8.75217391 C5.6573913,8.52782609 5.55826087,6.94869565 5.29043478,6.65391304 C4.42618218,5.71999017 4.00218107,4.46127641 4.12521739,3.19478261 C4.04773225,2.38893184 4.3086861,1.58713092 4.84563208,0.981252693 C5.38257805,0.375374466 6.14719987,0.0199379717 6.95652174,0 C7.7658436,0.0199379717 8.53046543,0.375374466 9.0674114,0.981252693 C9.60435737,1.58713092 9.86531123,2.38893184 9.78782609,3.19478261 C9.91179253,4.46076001 9.48908072,5.71940342 8.62608696,6.65391304 C8.44145379,7.01512695 8.37051557,7.42377961 8.4226087,7.82608696"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
