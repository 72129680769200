import api, {apiError} from './api';
import {AxiosResponse} from 'axios';
import {collection} from '@/interfaces/collection';

interface collectionInterface {
    view(): Promise<AxiosResponse<Array<collection> | apiError>>;
    get(id: number): Promise<AxiosResponse<collection | apiError>>;
    add(collection_id: number, media_id: number): Promise<AxiosResponse<unknown | apiError>>;
    delete(collection_id: number, media_id: number): Promise<AxiosResponse<unknown | apiError>>;
}

class CollectionService implements collectionInterface {
    view(): Promise<AxiosResponse<Array<collection>>> {
        return api.get('/collections');
    }

    get(id: number): Promise<AxiosResponse<collection | apiError>> {
        return api.get('/collections/' + id);
    }

    add(collection_id: number, media_id: number): Promise<AxiosResponse<unknown | apiError>> {
        return api.post('/collection/' + collection_id + '/media/' + media_id);
    }

    delete(collection_id: number, media_id: number): Promise<AxiosResponse<unknown | apiError>> {
        return api.delete('/collection/' + collection_id + '/media/' + media_id);
    }
}

export default new CollectionService();
