import { createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c29e96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-overlay" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("iframe", {
        ref: "chargebeeiframe",
        class: "chargebeeiframe",
        src: _ctx.id,
        title: "Test"
      }, null, 8, _hoisted_2)
    ])
  ]))
}