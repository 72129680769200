import api, { apiError } from './api';
import { AxiosResponse, CancelToken } from 'axios';
import axios from 'axios';

interface s3File {
  uuid: string;
  content_type: string;
  type: string;
}

interface uploadInterface {
  uploadProgress: number;
  upload(file: File): Promise<AxiosResponse<uploadRes | apiError>>;
}

interface signedUrlRes {
  uuid: string;
  bucket: string;
  key: string;
  url: string;
  headers: {
    Host: Array<string>;
    'Content-Type': string;
    'x-amz-acl': Array<string>;
  };
}

interface vaporOptions {
  bucket?: string;
  contentType?: string;
  expires?: string;
  visibility?: string;
  baseURL?: string;
  headers?: string;
  cancelToken?: CancelToken;
  // Progress might be used, see https://www.npmjs.com/package/laravel-vapor
  // on how to implement it.
  // progress?: Function;
}

interface uploadRes {
    upload_url: string
}

class uploadService implements uploadInterface {
  uploadProgress = 0;

  async upload(file: File): Promise<AxiosResponse<uploadRes | apiError>> {
      const res: signedUrlRes = await this.vaporStore(file, {
          baseURL: 'https://'+process.env.VUE_APP_API_URL,
          bucket: 'jumpstory',
      });

      return this.uploadToS3({
          uuid: res.uuid,
          content_type: 'image/png',
          type: 'PHOTO',
      });
  }

  public getUploadprogress() {
      return this.uploadProgress;
  }

  private uploadToS3(file: s3File) {
      return api.post('user/collections/17/upload/PHOTO', file);
  }

  // Rewritten for typescript from https://www.npmjs.com/package/laravel-vapor
  private async vaporStore(
      file: File,
      options: vaporOptions = {}
  ): Promise<signedUrlRes> {
      // Will use options once the production setup is ready.
      console.log(options);
      const response = await api.get('user/collections/17/upload');

      const headers = response.data.headers;

      if ('Host' in headers) {
          delete headers.Host;
      }

      await axios.put(response.data.upload_url, file, {
          headers: headers,

      });

      response.data.extension = file.name.split('.').pop();

      return response.data;
  }
}

const UploadService: uploadInterface = new uploadService();

export default UploadService;
