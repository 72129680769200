import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c459bec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 1,
  class: "header"
}
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = { key: "show-media-video" }
const _hoisted_9 = { key: "show-media" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_ThumbModal = _resolveComponent("ThumbModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container grid-item",
    id: _ctx.triggerNextLoad === true ? 'triggerNextLoad' : null
  }, [
    (!_ctx.isPublic && _ctx.isCollection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            id: 'move-' + _ctx.images[_ctx.index].id,
            class: "header-move-file",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveFile()))
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'folder-open'],
                class: "icon arrow alt"
              })
            ])
          ], 8, _hoisted_3),
          _createElementVNode("div", {
            id: 'remove-' + _ctx.images[_ctx.index].id,
            class: "header-remove-file",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeFile()))
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'folder-minus'],
                class: "icon arrow alt"
              })
            ])
          ], 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isCollection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            id: 'move-' + _ctx.images[_ctx.index].id,
            class: "header-move-file",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleFavorite()))
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'heart'],
                class: _normalizeClass(_ctx.images[_ctx.index].is_favorite ? 'icon alt is-favorite' : 'icon alt')
              }, null, 8, ["class"])
            ])
          ], 8, _hoisted_6),
          _createElementVNode("div", {
            id: 'remove-' + _ctx.images[_ctx.index].id,
            class: "header-remove-file",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadMedia()))
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'download'],
                class: "icon alt"
              })
            ])
          ], 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleThumbModal()))
    }, [
      (_ctx.images[_ctx.index].type === 'VIDEO')
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
            _createVNode(_component_video_player, {
              images: _ctx.images,
              index: _ctx.index,
              controls: false,
              muted: true,
              loop: true,
              "enable-pause": true
            }, null, 8, ["images", "index"])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_9, [
            _createElementVNode("img", {
              src: _ctx.images[_ctx.index].thumbnail_url,
              class: "img"
            }, null, 8, _hoisted_10)
          ]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
      _createVNode(_component_ThumbModal, {
        enabled: _ctx.thumbModalEnabled,
        images: _ctx.images,
        "media-data": _ctx.media,
        index: _ctx.index,
        "search-id": _ctx.searchId,
        "page-id": _ctx.pageId,
        "media-id": _ctx.mediaId,
        update: _ctx.update,
        onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.thumbModalEnabled = false))
      }, null, 8, ["enabled", "images", "media-data", "index", "search-id", "page-id", "media-id", "update"])
    ]))
  ], 8, _hoisted_1))
}