<template>
  <path
    d="M15.543,15.543,12.915,8.972c-.2-.511-.558-.519-.785-.018l-2.087,4.589L8.184,11.312a.667.667,0,0,0-1.155.089L4.543,15.543"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M1.543 1.543 L18.543 1.543 L18.543 18.543 L1.543 18.543 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M20.551,7.424l1,.091A1,1,0,0,1,22.452,8.6L21.271,21.548a1,1,0,0,1-1.087.9L6.243,21.18"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M1.543 15.543L18.543 15.543"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M4.543 6.043 A1.500 1.500 0 1 0 7.543 6.043 A1.500 1.500 0 1 0 4.543 6.043 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
