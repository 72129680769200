
import { Options, Vue } from 'vue-class-component';
import BaseView from '@/views/BaseView/BaseView.vue';
import SearchQuickSearches from '@/components/Search/SearchQuickSearches.vue';
import BaseSpinner from '@/components/global/UI/BaseSpinner.vue';
import search, { searchRes } from '@/services/searchService';
import { Image } from '@/interfaces/Media';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import ThumbCollection from '@/components/global/ThumbCollection/ThumbCollection.vue';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Filters } from '@/store/modules/filters/media_filters';
import Swal from 'sweetalert2';
import { setDefaultAlertOptions } from '@/components/Account/ChangePassword.vue';

@Options({
    components: {
        BaseView,
        SearchQuickSearches,
        BaseSpinner,
        BaseIcon,
        ThumbCollection,
    }
})
export default class TextMatch extends Vue {
    store!: Store<RootState>;
    filters!: Filters;
    images!: Array<Image>;
    toggleTempPage = false;
    toggleResponse = false;
    isLoading = false;
    showImages = false;
    showResponse = false;

    imagesLoaded = false;
    totalHits?: number = 0;
    searchId!: number;
    pageId!: number;
    isFirstPage!: boolean;
    isLastPage!: boolean;
    currentPage = 1;
    nextPage = 1;

    text = '';

    mockKeywords = ['tree','forest','nature','water','flower'];

    mounted(): void {
        this.store = useStore();
        this.filters = this.store.getters.filters;
    }

    checkText(): void {
        if (this.text) {
            this.isLoading = true;
            this.showImages = false;
            this.showResponse = false;

            // ToDo: use textmatch endpoint

            // For testing purposes only
            this.filters.search_string = 'tree';

            search.search(this.filters.formatForApi()).then((res) => {
                const searchResponse = res.data as searchRes;
                if (searchResponse?.page?.media) {
                    this.images = searchResponse.page.media;
                    this.searchId = searchResponse.id;
                    this.pageId = searchResponse.page.id;
                    this.isFirstPage = searchResponse.page.is_first;
                    this.isLastPage = searchResponse.page.is_last;
                    this.imagesLoaded = true;
                    this.nextPage += 1;
                    this.totalHits = searchResponse.total_hits;
                    this.showImages = true;
                }
            });

            setTimeout(() => {
                this.isLoading = false;
                this.toggleResponse = true;
                this.showResponse = this.showImages;
            },2000);
        } else {
            const noTextAlert = Swal.mixin(setDefaultAlertOptions());

            noTextAlert.fire({
                title: 'Missing text',
                text: 'Please add a text to the text field, before you click the "Match Text Now" button',
            });
        }
    }

    initSearch(keyword: string): void {
        this.filters.search_string = keyword;
        this.filters.search_input = keyword;
        search.search(this.filters.formatForApi());
        this.$router.push('/search');
    }
}
