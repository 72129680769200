
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { useStore, Store } from 'vuex';
import { RootState } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        BaseIcon
    }
})

export default class SearchMenuHeader extends Vue {
    store!: Store<RootState>;
    filters!: Filters;

    @Watch('$store.getters.filters', { immediate: true, deep: true })
    onFiltersChange(): void {
        if (this.filters && this.filters.collapse) {
            const filterMenuHeader: HTMLDivElement = this.$refs.filterMenuHeader as HTMLDivElement;
            filterMenuHeader.style.top = '61px';
            const filterMenuClose: HTMLDivElement = this.$refs.filterMenuClose as HTMLDivElement;
            filterMenuClose.style.display = 'block';
        }
    }

    mounted(): void {
        this.store = useStore();
        this.filters = this.store.getters.filters;
    }

    closeOverlay(): void {
        this.filters.collapse = false;
    }
}
