<template>
  <polygon
    id="Path"
    stroke="#000000"
    points="0 0 16 0 16 11.1304348 0 11.1304348"
  ></polygon>
  <line
    x1="0"
    y1="2.7826087"
    x2="16"
    y2="2.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="13.9130435"
    y1="5.56521739"
    x2="11.826087"
    y2="5.56521739"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="7.65217391"
    y1="5.56521739"
    x2="2.08695652"
    y2="5.56521739"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="4.17391304"
    y1="7.65217391"
    x2="2.08695652"
    y2="7.65217391"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
