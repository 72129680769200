
import { Options, Vue } from 'vue-class-component';
import IconButton from '../UI/IconButton.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Tooltip from '../UI/Tooltip.vue';
import { tooltip } from './ThumbModal.vue';

@Options({
    props: {
        tooltip: {
            type: Object,
            required: false,
        }
    },
    components: {
        IconButton,
        BaseIcon,
        Tooltip
    },
})
export default class ActionsToggler extends Vue {
  tooltip!: tooltip;
}
