
import { Vue, Options } from 'vue-class-component';
import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import router from '@/router';
import { updatePasswordPayload } from './AuthInterfaces';
import userService from '@/services/userService';
import { AxiosResponse } from 'axios';
import { tokenResPayload } from '@/store/modules/auth/index';
import Swal from 'sweetalert2';
import { setDefaultAlertOptions } from '@/components/Account/ChangePassword.vue';

@Options({
    components: {},
})
export default class ResetPasswordCallbackForm extends Vue {
  token = '';
  password = '';
  passwordCheck = '';

  store!: Store<RootState>;

  mounted(): void {
      this.store = useStore();
      this.token = this.$route?.query?.token as string;
  }

  async submitForm(): Promise<void> {
      const resetPasswordAlert = Swal.mixin(setDefaultAlertOptions());

      if (
          this.token === '' ||
          this.token.length !== 60 ||
          this.password.length < 6
      ) {
          if (
              this.password !== this.passwordCheck
          ) {
              await resetPasswordAlert.fire({
                  title: 'Wrong input',
                  text: 'Either your token is wrong, or your password is not at least 6 characters long.',
              });
              return;
          }
          return;
      }
      if (
          this.password !== this.passwordCheck
      ) {
          await resetPasswordAlert.fire({
              title: 'Wrong input',
              text: 'Your passwords needs to match.',
          });
          return;
      }
      this.$emit('update:loading', true);
      await this.resetPassword();
      this.$emit('update:loading', false);
  }

  async resetPassword(): Promise<void> {
      const payload: updatePasswordPayload = {
          token: this.token,
          password: this.password
      };
      const authResult: AxiosResponse<tokenResPayload> = await this.store.dispatch('setNewPassword', payload);

      if (authResult.status === 200) {
          userService.get(2).then(
              (user) => {
                  console.log(user);
              },
              (err) => {
                  console.log('User err');
                  console.log(err);
              }
          );
          router.push('/');
      }
  }
}
