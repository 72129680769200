<template>
  <path
    d="M12,.71a7.5,7.5,0,0,1,7.5,7.5c0,3.547-5.5,12.381-7.079,14.85a.5.5,0,0,1-.842,0C10,20.592,4.5,11.757,4.5,8.21A7.5,7.5,0,0,1,12,.71Z"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M9.000 8.210 A3.000 3.000 0 1 0 15.000 8.210 A3.000 3.000 0 1 0 9.000 8.210 Z"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
