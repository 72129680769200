
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { Watch } from 'vue-property-decorator';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import SearchMenuAccordion from './SearchMenuAccordion.vue';
import Accordion from '@/components/global/Accordion/Accordion.vue';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        SearchMenuAccordion,
        Accordion,
        BaseIcon
    }
})
export default class SearchMenuMediaType extends Vue {
    store!: Store<RootState>;
    filters!: Filters;
    open = true;

    @Watch('$store.getters.filters', { immediate: true, deep: true })
    onFiltersChange(): void {
        if (this.filters && this.filters.close_all) {
            this.open = false;
        }
    }

    mounted(): void {
        this.store = useStore();
        this.filters = this.store.getters.filters;
    }

    toggleAccordionIcon(): void {
        this.open = !this.open;
    }
}
