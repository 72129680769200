
import { Options, Vue } from 'vue-class-component';
import VideoPlayer from '@/components/global/Video/VideoPlayer.vue';
import { Media } from '@/interfaces/Media';

@Options({
    props: {
        images: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        media: {
            type: Object,
            required: true
        },
        showBackgroundRemoveDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
        VideoPlayer
    }
})

export default class ThumbnailHelper extends Vue {
    media!: Media;
    showMessage!: boolean;
}
