import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionMenu = _resolveComponent("CollectionMenu")!
  const _component_CollectionIndex = _resolveComponent("CollectionIndex")!
  const _component_base_view = _resolveComponent("base-view")!

  return (_openBlock(), _createBlock(_component_base_view, { "enable-menu": true }, {
    menu: _withCtx(() => [
      _createVNode(_component_CollectionMenu)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CollectionIndex)
    ]),
    _: 1
  }))
}