<template>
  <path
    d="M9.04347826,3.13043478 L9.04347826,0.695652174 C9.04347826,0.311454087 8.73202417,0 8.34782609,0 L0.695652174,0 C0.311454087,0 0,0.311454087 0,0.695652174 L0,15.3043478 C0,15.6885459 0.311454087,16 0.695652174,16 L8.34782609,16 C8.73202417,16 9.04347826,15.6885459 9.04347826,15.3043478 L9.04347826,12.8695652"
    id="Path"
    stroke="#000000"
  ></path>
  <line x1="16" y1="8" x2="2.7826087" y2="8" id="Path" stroke="#000000"></line>
  <polyline
    id="Path"
    stroke="#000000"
    points="12.5217391 11.4782609 16 8 12.5217391 4.52173913"
  ></polyline>
</template>
;
