import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSearch,
    faBars,
    faArchive,
    faImage,
    faUserFriends,
    faFileAlt,
    faTh,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faFolderOpen,
    faFolderMinus,
    faCheck,
    faPlus,
    faTimesCircle,
    faTimes,
    faDownload,
    faHeart
} from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { loadIcons } from './iconsLoader';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import './styles/alerts.css';
import './styles/forms.css';
import './styles/video-player.css';

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URL,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [
        new Integrations.BrowserTracing(),
        // new Sentry.Integrations.Breadcrumbs({
        //     console: false
        // })
    ],
    tracesSampleRate: 1.0,
});

const app = createApp(App);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
app.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err);
};

app.use(store);
app.use(router);
app.use(Vue3VideoPlayer);
library.add(
    faSearch,
    faBars,
    faArchive,
    faImage,
    faUserFriends,
    faFileAlt,
    faTh,
    faFolder,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faFolderOpen,
    faFolderMinus,
    faCheck,
    faPlus,
    faTimesCircle,
    faTimes,
    faDownload,
    faHeart
);
app.component('font-awesome-icon', FontAwesomeIcon);

// Do not import components globally.! 
// Import them directly into the components where they are used.
// With the exception of icons.
loadIcons(app);

app.mount('#app');

window.addEventListener('error', (event) => {
    Sentry.captureException(event);
});
window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event);
});
