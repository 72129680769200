
import { Options, Vue } from 'vue-class-component';
import search from '@/services/searchService';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        BaseIcon,
    },
    emits: ['close']
})
export default class SearchField extends Vue {
  store!: Store<RootState>;
  toggleReset = false;
  toggleSuggestion = false;
  suggestionIndex = -1;
  autoComplete?: Array<string>;
  filters!: Filters;
  isMounted = false;
  inputValue = '';

  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters) {
          this.inputValue = this.filters.search_input;
      }
  }

  created(): void {
      this.store = useStore();
  }

  mounted(): void {
      this.filters = this.store.getters.filters;
      this.isMounted = true;
  }

  inputFocus(): void {
      const input = <HTMLInputElement>this.$refs.searchInput;
      input.focus();
  }

  resetToggles(): void {
      const searchInput: HTMLInputElement = this.$refs.searchInput as HTMLInputElement;
      searchInput.blur();
      this.toggleReset = false;
      this.toggleSuggestion = false;
  }

  searchClick(): void {
      this.resetToggles();
      this.filters.search_string = this.inputValue;
      this.filters.search_input = this.inputValue;
      this.$router.push('/search');
  }

  changeKeywordAndSearch(keyword: string): void {
      this.resetToggles();
      this.filters.search_string = keyword;
      this.filters.search_input = keyword;
      this.inputValue = keyword;
      this.$router.push('/search');
  }

  enableReset(e: string): void {
      this.toggleReset = false;
      this.toggleSuggestion = false;
      if (e) {
          this.toggleReset = true;
          if (e.length > 1) {
              search
              // @ts-ignore
                  .autoComplete(e)
                  .then((res) => {
                      if (res?.data) {
                          this.autoComplete = res.data as string[];
                          if (this.autoComplete && this.autoComplete.length > 0) {
                              this.toggleSuggestion = true;

                              const searchSuggestion: HTMLDivElement = this.$refs.searchSuggestion as HTMLDivElement;
                              const inputContainer: HTMLDivElement = this.$refs.inputContainer as HTMLDivElement;

                              if (searchSuggestion) {
                                  searchSuggestion.style.width = String(inputContainer.offsetWidth) + 'px';
                              }

                              // eslint-disable-next-line @typescript-eslint/no-this-alias
                              const self = this;
                              setTimeout(() => {
                                  self.toggleSuggestion = false;
                              }, 7000);
                          }
                      }
                  });

              // @ts-ignore
              document.addEventListener('keydown', this.checkKeyPress);
          }
      }
  }

  resetInput(): void {
      this.filters.search_string = '';
      this.filters.search_input = '';
      this.toggleReset = false;
      this.toggleSuggestion = false;
  }

  checkKeyPress(event: KeyboardEvent): void {
      const suggestionList = document.querySelector('.search-suggestion');
      let index = this.suggestionIndex;

      if (suggestionList) {
          let len = suggestionList.getElementsByTagName('div').length - 1;
          let first = suggestionList.getElementsByTagName('div')[0];

          if (index === 0) {
              first.classList.add('selected');
          }
          if (index > 1) {
              first.classList.remove('selected');
          }

          if (event.which === 40) {
              if (index < len++) {
                  if (index > -1) {
                      let prev = suggestionList.getElementsByTagName('div')[index];
                      prev.classList.remove('selected');
                  }

                  index++;

                  let next = suggestionList.getElementsByTagName('div')[index];
                  next.classList.add('selected');
              }
          } else if (event.which === 38) {
              let next = suggestionList.getElementsByTagName('div')[index];
              next.classList.remove('selected');

              index--;

              if (index > -1) {
                  let prev = suggestionList.getElementsByTagName('div')[index];
                  prev.classList.add('selected');
              }
          }

          this.suggestionIndex = index;
      }
  }

  toggleFilters(): void {
      this.filters.collapse = true;
  }
}
