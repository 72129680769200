
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        enableMenu: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
})
export default class ThumbCollection extends Vue {

}
