import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { MediaView } from '@/store/modules/media/media';


@Module
export default class media extends VuexModule {
    Media!: MediaView;

    constructor() {
        super(VuexModule);
        this.Media = new MediaView();
    }

    @Mutation
    setMedia(media: MediaView): void {
        this.Media = media;
        localStorage.setItem('media', JSON.stringify(media));
    }

    get media(): MediaView {
        return this.Media;
    }

}