<template>
  <path
    d="M0.498 0.497 L23.498 0.497 L23.498 23.497 L0.498 23.497 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
