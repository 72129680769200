
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ThumbAddToCollectionModal from '@/components/global/ThumbModal/ThumbAddToCollectionModal.vue';
import EditModal from '@/components/global/EditModal/EditModal.vue';
import BaseSlider from '@/components/global/UI/BaseSlider.vue';
import { Image, Media } from '@/interfaces/Media';
import userCollection from '@/services/UserCollectionService';
import { collection } from '@/interfaces/collection';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import IconButton from '@/components/global/UI/IconButton.vue';
import BaseDialog from '@/components/global/UI/BaseDialog.vue';
import Tooltip from '@/components/global/UI/Tooltip.vue';
import EditModalToggler from './EditModalToggler.vue';
import IsFavoriteToggler from './IsFavoriteToggler.vue';
import RemoveBackgroundHelper from './RemoveBackgroundHelper.vue';
import DownloadHelper from './DownloadHelper.vue';
import RemoveBackgroundToggler from './RemoveBackgroundToggler.vue';
import AddToCollectionToggler from './AddToCollectionToggler.vue';
import ActionsToggler from './ActionsToggler.vue';
import search, { viewRes } from '@/services/searchService';
import { DownloadableFile } from '@/interfaces/DownloadableFile';
import VideoPlayer from '@/components/global/Video/VideoPlayer.vue';
import { MediaView } from '@/interfaces/MediaView';
import { mediaProps } from '@/store/modules/media/media';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import ThumbnailHelper from './ThumbnailHelper.vue';
import ThumbModalLoader from '@/components/global/ThumbModal/ThumbModalLoader.vue';

export interface tooltip {
  remove_background: string;
  remove_background_disabled: string;
  add_to_collection: string;
  add_to_favorites: string;
  remove_from_favorites: string;
  download_media: string;
}

@Options({
    props: {
        images: {
            type: Array,
            required: true,
        },
        mediaData: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true,
        },
        searchId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        mediaId: {
            type: Number,
            required: true
        },
        enabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        update: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ThumbModalLoader,
        ThumbnailHelper,
        ThumbAddToCollectionModal,
        EditModal,
        BaseSlider,
        BaseIcon,
        IconButton,
        BaseDialog,
        Tooltip,
        EditModalToggler,
        IsFavoriteToggler,
        RemoveBackgroundHelper,
        DownloadHelper,
        RemoveBackgroundToggler,
        AddToCollectionToggler,
        ActionsToggler,
        VideoPlayer,
    },
    emits: ['close', 'update']
})
export default class ThumbModal extends Vue {
  store!: Store<RootState>;
  images!: Array<Image>;
  media!: Media;
  mediaData!: Media;
  mediaView!: MediaView;
  componentKey = 0;

  index!: number;
  currentIndex = 0;

  searchId!: number;
  pageId!: number;
  mediaId!: number;
  key!: string;
  editType = 'ORIGINAL';
  viewResponse!: viewRes;

  enabled!: boolean;

  isBackgroundRemoved = false;
  preventRemoveBgToggle = false;
  backgroundRemoveDisabled = false;
  showBgDisabledMessage = false;
  disableBgRemoveButton = false;
  downloadSizeResourceType = 'original';
  displayLoader = false;

  favorites!: collection;
  collectionFavoriteId = 0;
  isFavorite = false;

  addToCollectionModalEnabled = false;
  editModalEnabled = false;
  actionsModalEnabled = false;
  propHeight = 1000;

  tooltip: tooltip = {
      remove_background: 'Remove background for this image',
      remove_background_disabled: 'Background removal is not possible for this image',
      add_to_collection: 'Add to collection',
      add_to_favorites: 'Add to favorites',
      remove_from_favorites: 'Remove from favorites',
      download_media: 'Choose download size'
  };

  similar_images = [
      {
          id: '3715903',
          width: 3456,
          height: 2304,
          orientation: 'horizontal',
          title: 'a car parked in a parking lot',
          thumbnail_url:
        'https://d2h1ar7vaf6ci0.cloudfront.net/a546e4fa-757c-4b52-9930-e156b99abba3.jpg',
      },
      {
          id: '3178667',
          width: 4418,
          height: 2945,
          orientation: 'horizontal',
          title: 'a large long train on a highway',
          thumbnail_url:
        'https://d2h1ar7vaf6ci0.cloudfront.net/56f5bb83-7b5a-432f-a25a-80370d0387ff.jpg',
      },
      {
          id: '29993',
          width: 3456,
          height: 5184,
          orientation: 'vertical',
          title: 'a view of a city at night',
          thumbnail_url:
        'https://d2h1ar7vaf6ci0.cloudfront.net/2f4b651f-16ce-4d9a-9cb8-1775234182a8.jpg',
      },
      {
          id: '1372',
          width: 4000,
          height: 2667,
          orientation: 'horizontal',
          title: 'a close up of a device',
          thumbnail_url:
        'https://d2h1ar7vaf6ci0.cloudfront.net/8aba38fb-a38f-428c-81a5-99ede80f7ff1.jpg',
      },
      {
          id: '5265115',
          width: 4692,
          height: 3128,
          orientation: 'horizontal',
          title: '',
          thumbnail_url:
        'https://d2h1ar7vaf6ci0.cloudfront.net/9cfbae36-7501-446d-94df-735694974b97.jpg',
      },
  ];

  @Watch('enabled')
  onChildChanged(val: string, oldVal: string): void {
      if (val === oldVal) {
          return;
      }
      if (val) {
          if (!this.mediaView) {
              this.createMediaView();
          }
          this.key = this.mediaData.key;
          this.media = this.mediaData;

          search.view(this.searchId, this.pageId, this.mediaId, this.key, 'ORIGINAL').then((res) => {
              this.media = res.data as viewRes;
              this.editType === 'BACKGROUND_REMOVED' ? this.mediaView.type_background_removed = this.media : this.mediaView.type_original = this.media;
              this.isFavorite = this.media.is_favorite;
              this.backgroundRemoveDisabled = this.media.is_background_remove_disabled;
              this.isBackgroundRemoved = false;
              this.componentKey = this.updateComponentKey();
          });

          // @ts-ignore
          window.addEventListener('keydown', this.changeWithKeys());
      } else {
          this.mediaView.type_original = new mediaProps();
          this.mediaView.type_background_removed = new mediaProps();
          // @ts-ignore
          window.removeEventListener('keydown', this.changeWithKeys());
      }
  }

  @Watch('update')
  onUpdateChanged(val: boolean): void {
      if (val) {
          const viewResponse = this.getMediaView(this.editType);
          if (viewResponse) {
              this.media = viewResponse;
              if (this.enabled) {
                  if (!this.mediaView) {
                      this.createMediaView();
                  }
                  this.editType === 'BACKGROUND_REMOVED' ? this.mediaView.type_background_removed = viewResponse : this.mediaView.type_original = viewResponse;
                  this.isFavorite = this.media.is_favorite;
                  this.backgroundRemoveDisabled = this.media.is_background_remove_disabled;
              }
          }
      }
  }

  @Watch('$store.getters.media', { immediate: true, deep: true })
  onMediaChange(): void {
      if (this.enabled) {
          if (!this.mediaView) {
              this.createMediaView();
          }
          if (this.editType === 'BACKGROUND_REMOVED') {
              this.media = this.mediaView.type_background_removed;
          } else {
              this.media = this.mediaView.type_original;
          }
          this.componentKey = this.updateComponentKey();
      }
  }


  mounted(): void {
      // this.store = useStore();
      // this.mediaView = this.store.getters.media;
      this.media = this.mediaData;
      this.currentIndex = this.index;
      this.componentKey = this.updateComponentKey();
  }

  getMediaView(editType: string): viewRes {
      search.view(this.searchId, this.pageId, this.mediaId, this.key, editType).then((res) => {
          this.viewResponse = res.data as viewRes;
      });
      return this.viewResponse;
  }

  createMediaView(): void {
      const mediaView = {
          'type_original': new mediaProps(),
          'type_background_removed': new mediaProps()
      };
      this.mediaView = mediaView;
  }

  toggleRemoveBackground(): void {
      if (!this.preventRemoveBgToggle) {
          if (this.editType === 'BACKGROUND_REMOVED') {
              this.editType = 'ORIGINAL';
              this.media = this.mediaView.type_original;
          } else {
              this.editType = 'BACKGROUND_REMOVED';
              this.media = this.mediaView.type_background_removed;
          }
          this.componentKey = this.updateComponentKey();
      }
  }

  preventToggle(state: boolean): void {
      this.preventRemoveBgToggle = state;
  }

  removeBackground(): void {
      this.key = this.media.key ?? '';
      this.displayLoader = true;

      if (this.backgroundRemoveDisabled) {
          setTimeout(() => {
              this.showBgDisabledMessage = true;
              this.disableBgRemoveButton = true;
              this.componentKey = this.updateComponentKey();
              this.displayLoader = false;
          }, 3000);
          setTimeout(() => { this.showBgDisabledMessage = false; }, 6000);
      } else {
          if (this.mediaView.type_background_removed.id !== '') {
              setTimeout(() => {
                  this.media = this.mediaView.type_background_removed;
              }, 3000);
          } else {
              search.view(this.searchId, this.pageId, this.mediaId, this.key, 'BACKGROUND_REMOVED').then((res) => {
                  if (res.status == 200) {
                      const viewResponse = res.data as viewRes;
                      if (viewResponse) {
                          this.media = viewResponse;
                          this.mediaView.type_background_removed = viewResponse;
                          this.editType = 'BACKGROUND_REMOVED';
                          this.isBackgroundRemoved = true;
                      }
                  } else {
                      this.backgroundRemoveDisabled = true;
                  }
                  this.displayLoader = false;
              });
          }
      }
  }

  toggleFavorite(): void {
      userCollection.favorites().then((res) => {
          if (res.status === 200) {
              if (res.data.id) {
                  this.isFavorite = this.isFavorite !== true;
                  if (this.isFavorite) {
                      userCollection.add(res.data.id, Number(this.mediaId)).then((res) => {
                          if (res.status !== 200) {
                              this.isFavorite = false;
                          }
                      });
                  } else {
                      userCollection.delete(res.data.id, Number(this.mediaId)).then((res) => {
                          if (res.status !== 200) {
                              this.isFavorite = true;
                          }
                      });
                  }
              }
          }
      });
  }

  toggleAddToCollectionModal(): void {
      this.addToCollectionModalEnabled = !this.addToCollectionModalEnabled;
  }

  toggleEditModal(): void {
      this.closeMe();
      this.editModalEnabled = !this.editModalEnabled;
  }

  toggleActionsModal(): void {
      this.actionsModalEnabled = !this.actionsModalEnabled;
  }

  downloadDefault(): void {
      const file = this.getFullSize(this.media);
      if (file) {
          search.download(this.searchId, this.pageId, this.mediaId, this.media.key, this.editType, file.id);
      }
  }

  downloadSelectedSize(fileId: number): void {
      search.download(this.searchId, this.pageId, this.mediaId, this.media.key, this.editType, fileId);
  }

  getFullSize(image: Image): DownloadableFile {
      let downloadFile: DownloadableFile;
      image.downloadable_files.forEach((file) => {
          if (file.size === 'FULL') {
              downloadFile = file;
          }
      });
      // @ts-ignore
      return downloadFile;
  }

  nextImage(): void {
      if (this.currentIndex > this.images.length / 2) {
          // @ts-ignore
          this.$parent.$parent.$emit('update');
      }
      if (this.currentIndex + 1 < this.images.length) {
          this.currentIndex = this.currentIndex + 1;
      }
  }

  previousImage(): void {
      if (this.currentIndex > 0) {
          this.currentIndex = this.currentIndex - 1;
      }
  }

  /* eslint-disable */
  changeWithKeys(): Function {
    return (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowLeft":
          this.previousImage();
          break;
        case "ArrowRight":
          this.nextImage();
          break;
        case "Escape":
          this.closeMe();
          break;
      }
    };
  }

  closeMe(): void {
    this.currentIndex = this.index;
    this.showBgDisabledMessage = false;
    this.$emit("close");
  }

  setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  updateComponentKey(): number {
    return this.componentKey += 1;
  }
}
