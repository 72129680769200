import api, {apiError} from './api';
import {AxiosResponse} from 'axios';
import {collection} from '@/interfaces/collection';

interface UserCollectionInterface {
    view(): Promise<AxiosResponse<Array<collection>>>;

    get(id: number): Promise<AxiosResponse<collection | apiError>>;

    add(collection_id: number, media_id: number): Promise<AxiosResponse<collection | apiError>>;

    favorites(): Promise<AxiosResponse<collection>>;

    create(name: string): Promise<AxiosResponse<collection | apiError>>;

    update(id: number, name: string): Promise<AxiosResponse<collection | apiError>>;

    delete(collection_id: number, media_id: number): Promise<AxiosResponse<collection | apiError>>;
}

class UserCollectionService implements UserCollectionInterface {
    view(): Promise<AxiosResponse<Array<collection>>> {
        return api.get('/user/collections');
    }

    get(id: number): Promise<AxiosResponse<collection>> {
        return api.get('/collections/' + id);
    }

    add(collection_id: number, media_id: number): Promise<AxiosResponse<collection | apiError>> {
        return api.post('/user/collections/' + collection_id + '/media/' + media_id);
    }

    favorites(): Promise<AxiosResponse<collection>> {
        return api.get('/user/collections/favorites');
    }

    create(name: string): Promise<AxiosResponse<collection | apiError>> {
        return api.post('/user/collections', name);
    }

    update(id: number, name: string): Promise<AxiosResponse<collection | apiError>> {
        return api.put('/user/collections/' + id, name);
    }

    delete(collection_id: number, media_id: number): Promise<AxiosResponse<collection | apiError>> {
        return api.delete('/user/collections/' + collection_id + '/media/' + media_id);
    }
}

export default new UserCollectionService();
