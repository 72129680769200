<template>
  <line
    x1="14.6086957"
    y1="2.7826087"
    x2="14.6086957"
    y2="13.2173913"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="2.7826087"
    y1="1.39130435"
    x2="13.2173913"
    y2="1.39130435"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="1.39130435"
    y1="13.2173913"
    x2="1.39130435"
    y2="2.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="13.2173913"
    y1="14.6086957"
    x2="2.7826087"
    y2="14.6086957"
    id="Path"
    stroke="#000000"
  ></line>
  <polygon
    id="Path"
    stroke="#000000"
    points="0 0 2.7826087 0 2.7826087 2.7826087 0 2.7826087"
  ></polygon>
  <polygon
    id="Path"
    stroke="#000000"
    points="13.2173913 0 16 0 16 2.7826087 13.2173913 2.7826087"
  ></polygon>
  <polygon
    id="Path"
    stroke="#000000"
    points="0 13.2173913 2.7826087 13.2173913 2.7826087 16 0 16"
  ></polygon>
  <polygon
    id="Path"
    stroke="#000000"
    points="13.2173913 13.2173913 16 13.2173913 16 16 13.2173913 16"
  ></polygon>
</template>
;
