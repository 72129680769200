interface hsl {
    h: number;
    s: number;
    l: number;
}

export function getFilterTypeMap(value: string): Map<string, string> {
    return new Map([
        ['media_type_PHOTO', 'Photos'],
        ['media_type_VIDEO', 'Videos'],
        ['media_type_VECTOR', 'Vectors'],
        ['media_type_ILLUSTRATION', 'Illustrations'],
        ['media_type_ICON', 'Icons'],
        ['orientation_VERTICAL', 'Vertical'],
        ['orientation_HORIZONTAL', 'Horizontal'],
        ['orientation_SQUARE', 'Square'],
        ['safesearch_on', 'SafeSearch On'],
        ['safesearch_off', 'SafeSearch Off'],
        ['people_true', 'With people'],
        ['people_false', 'Without people'],
        ['color_monochrome', 'Monochrome'],
        ['color_0_100_50', 'Red'],
        ['color_39_100_50', 'Orange'],
        ['color_60_100_50', 'Yellow'],
        ['color_120_100_25', 'Green'],
        ['color_174_72_56', 'Turquoise'],
        ['color_240_100_50', 'Blue'],
        ['color_203_94_50', 'Light Blue'],
        ['color_300_98_25', 'Purple'],
        ['color_350_100_88', 'Pink'],
        ['color_11_53_41', 'Brown'],
        ['color_60_56_91', 'Beige'],
        ['color_0_0_50', 'Grey'],
        ['people_with', 'With people'],
        ['people_without', 'Without people'],
        ['safesearch_on', 'Safe Search on'],
        ['safesearch_off', 'Safe Search off'],
        ['minheight', 'Min. height: ' + value + ' px.'],
        ['maxheight', 'Max. height: ' + value + ' px.'],
        ['minwidth', 'Min. width: ' + value + ' px.'],
        ['maxwidth', 'Max. width: ' + value + ' px.'],
        ['minduration', 'Min. duration: ' + value + ' sec.'],
        ['maxduration', 'Max. duration: ' + value + ' sec.'],
        [
            'color',
            '<div style=\'height:17px;width:17px;background-color:' +
            convertHSLToHex(value) +
            ';border-radius:2px;\'>&nbsp;</div>',
        ],
    ]);
}
// @ts-ignore
export function toggleFiltersByType(type: string): Array<string> {
    const filters = [];
    if (type) {
        // Add global filters
        filters.push('orientation', 'color', 'dimensions');

        if (type.includes('PHOTO')) {
            filters.push('people', 'safesearch', 'dpi');
        }

        if (type.includes('VIDEO')) {
            filters.push('duration');
        }
    }

    return filters;
}

export function convertHEXtoHSL(hex: string): hsl {
    hex = hex.replace(/#/g, '');
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map(function (hex) {
                return hex + hex;
            })
            .join('');
    }
    const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(
        hex
    );
    if (!result) {
        return {h: 0, s: 0, l: 0};
    }
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h,
        s,
        l = (max + min) / 2;
    if (max == min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
        case g:
            h = (b - r) / d + 2;
            break;
        case b:
            h = (r - g) / d + 4;
            break;
        }
        if (h) {
            h /= 6;
        }
    }
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    if (h) {
        h = Math.round(360 * h);
    } else {
        h = 0;
    }

    return {
        h: h,
        s: s,
        l: l,
    };
}

export function convertHSLToHex(value: string): string {
    const hsl = value.split(',');

    const h = parseInt(hsl[0]);
    let s = parseInt(hsl[1]);
    let l = parseInt(hsl[2]);

    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2;

    let r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }

    let rr = r.toString();
    let gg = g.toString();
    let bb = b.toString();

    // Having obtained RGB, convert channels to hex
    rr = Math.round((r + m) * 255).toString(16);
    gg = Math.round((g + m) * 255).toString(16);
    bb = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (rr.length == 1) rr = '0' + rr;
    if (gg.length == 1) gg = '0' + gg;
    if (bb.length == 1) bb = '0' + bb;

    return '#' + rr + gg + bb;
}