<template>
  <polygon
    id="Path"
    stroke="#000000"
    points="4.86956522 4.86956534 11.1304348 4.86956534 11.1304348 11.1304349 4.86956522 11.1304349"
  ></polygon>
  <path
    d="M0,8 C0,10.8581249 1.52479133,13.4991411 4,14.9282034 C6.4752086,16.3572658 9.5247914,16.3572658 12,14.9282034 C14.4752087,13.4991411 16,10.8581249 16,8 C16,5.14187537 14.4752087,2.50085918 12,1.07179679 C9.5247914,-0.357265597 6.4752086,-0.357265597 4,1.07179679 C1.52479133,2.50085918 0,5.14187537 0,8 L0,8 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
