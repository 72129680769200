
import {Options, Vue} from 'vue-class-component';
import { Image } from '@/interfaces/Media';
// @ts-ignore
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export interface BreakPoints {
  600: { visibleSlides: number, slideMultiple: number },
  800: { visibleSlides: number, slideMultiple: number },
  900: { visibleSlides: number, slideMultiple: number },
  1200: { visibleSlides: number, slideMultiple: number },
}

@Options({
    props: {
        images: {
            type: Array,
            required: true
        },
        breakpoints: {
            type: Object,
            required: false
        }
    },
    components: {
        VueperSlides,
        VueperSlide,
    }
})
export default class BaseSlider extends Vue {
  breakpoints!: BreakPoints;
  images!: Array<Image>;

}
