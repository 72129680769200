import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "filterMenuContainer",
    id: _ctx.isScrollable ? 'filtermenu-container' : 'menu-container',
    style: _normalizeStyle(_ctx.isScrollable ? 'height:' + _ctx.contentViewport + 'px;' : '')
  }, [
    _createElementVNode("ul", {
      class: _normalizeClass(["content-menu-list", _ctx.isMobileMenu ? 'mobile-menu' : ''])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 12, _hoisted_1))
}