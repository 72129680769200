<template>
  <path
    d="M21 7L3 7"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M21 12L3 12"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M21 17L3 17"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
