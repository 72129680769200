import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_menu_item = _resolveComponent("content-menu-item")!
  const _component_content_menu = _resolveComponent("content-menu")!

  return (_openBlock(), _createBlock(_component_content_menu, null, {
    default: _withCtx(() => [
      _createVNode(_component_content_menu_item)
    ]),
    _: 1
  }))
}