
import { Vue, Options } from 'vue-class-component';
import { Image } from '@/interfaces/Media';
import userCollection from '@/services/UserCollectionService';
import Masonry from 'masonry-layout';
import { collection } from '../../interfaces/collection';
import BaseDialog from '@/components/global/UI/BaseDialog.vue';
import BaseButton from '@/components/global/UI/BaseButton.vue';
import ColorButton from '@/components/global/UI/ColorButton.vue';
import BaseInput from '@/components/global/UI/BaseInput.vue';

@Options({
    components: {
        BaseDialog,
        BaseButton,
        ColorButton,
        BaseInput
    },
    emits: ['close','create-folder-submitted'],
})
export default class CollectionCreateModal extends Vue {
        // @ts-ignore
        image: Image;
        // @ts-ignore
        masonry: Masonry;
        // @ts-ignore
        collection: collection;
        collections: Array<collection> = [];
        isMobile = false;
        title = '';
        errMessage = '';

        mounted(): void {
            this.isMobile = screen.width < 768;

            userCollection.view().then(res => {
                const collectionData = res.data;

                collectionData.forEach(collection => {
                    this.collections.push(collection);
                });
            });
        }

        createCollection(): void {
            const element = document.getElementById('create-form');
            if(element) {
                element.style.display = 'block';
            }

            const overlay_container = document.querySelector('.overlay-container');
            overlay_container?.classList.add('overlay-active');
            const overlay_image = document.querySelectorAll('.overlay-image');
            overlay_image?.forEach(elem => {
                elem.classList.add('overlay-opacity-active');
            });
            document.querySelector('.overlay-button')?.classList.add('overlay-opacity-active');
        }

        onSubmit(): void {
            if (this.title == '') {
                this.errMessage = 'Collection title is incomplete. Please fill out title field.';
                return;
            }

            this.$emit('create-folder-submitted', this.title);

            userCollection.create(this.title).then((res) => {
                this.collection = res.data as collection;
                if (res.status === 200) {
                    this.$emit('close');
                } else {
                    this.errMessage = 'Unable to create collection.';
                }
            });

            this.title = '';
        }

        closeMe(): void {
            this.$emit('close');
        }
}
