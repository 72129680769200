
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        min: {
            type: Number,
            required: false,
            default: 0,
        },
        max: {
            type: Number,
            required: false,
            default: 100,
        },
        rangeAStartVal: {
            type: Number,
            required: false,
            default: 0,
        },
        rangeBStartVal: {
            type: Number,
            required: false,
            default: 100,
        },
    },
})
export default class BaseRangeSelector extends Vue {
  isMounted = false;
  rangeA!: number;
  rangeB!: number;
  rangeAStartVal!: number;
  rangeBStartVal!: number;
  mounted(): void {
      this.rangeA = this.rangeAStartVal;
      this.rangeB = this.rangeBStartVal;
      this.isMounted = true;
  }

  rangeAChanged(): void {
      this.$emit('rangeA', this.rangeA);
  }
  rangeBChanged(): void {
      this.$emit('rangeB', this.rangeB);
  }
}
