<template>
  <path
    d="M0,8.69565217 C0,13.4981283 3.89317609,17.3913043 8.69565217,17.3913043 C13.4981283,17.3913043 17.3913043,13.4981283 17.3913043,8.69565217 C17.3913043,3.89317609 13.4981283,0 8.69565217,0 C3.89317609,0 0,3.89317609 0,8.69565217 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="20"
    y1="20"
    x2="14.8443478"
    y2="14.8443478"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
