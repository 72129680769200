import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../store';
import { refreshPayload } from '@/components/global/Auth/AuthInterfaces';
import Swal from 'sweetalert2';

export interface apiError {
    errCode: number;
    errMessage: string;
}

const conf: AxiosRequestConfig = {
    baseURL: 'https://' + process.env.VUE_APP_API_URL + '/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
};

// http: This instance is the JumpStory api http client, which automatically handles
// Token injection, 401 unauthorized login redirection and automatic refreshtoken.
const http: AxiosInstance = axios.create(conf);

http.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const expires = store.getters.expires;
        if (expires && expires < Date.now() / 1000) {
            const payload: refreshPayload = {
                refresh_token: store.getters.token,
            };
            return store.dispatch('refreshToken', payload).then(
                () => {
                    config.headers['Authorization'] = 'Bearer ' + store.getters.token;
                    return config;
                },
                () => {
                    return {};
                }
            );
        }

        config.headers['Authorization'] = 'Bearer ' + store.getters.token;
        return config;
    }
);

http.interceptors.response.use(
    (res: AxiosResponse) => {
        return res;
    },
    (err: AxiosError) => {
        if (err.response?.status === 403) {
            Swal.fire({
                title: 'An error occurred',
                text:
          'Your account has not been activated. Please check your email.',
            });
        }
    }
);


export default http;
