
import { Vue } from 'vue-class-component';
import search from '@/services/searchService';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { QuickSearch } from '@/interfaces/QuickSearch';
import { Filters } from '@/store/modules/filters/media_filters';

export default class SearchQuickSearches extends Vue {
  store!: Store<RootState>;
  filters!: Filters;
  quickSearches?: Array<QuickSearch> = [];

  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;

      search.quickSearch().then(
          (res) => {
              this.quickSearches = res.data as Array<QuickSearch>;
          },
          (err) => {
              console.log(err);
          }
      );
  }

  searchClick(keyword: string): void {
      this.filters.search_string = keyword;
      this.filters.search_input = keyword;
      search.search(this.filters.formatForApi());
      this.$router.push('/search');
  }
}
