<template>
  <path
    d="M7 7L14 14"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M14 7L7 14"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M0.500 10.500 A10.000 10.000 0 1 0 20.500 10.500 A10.000 10.000 0 1 0 0.500 10.500 Z"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M23.5 23.5L17.571 17.571"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
