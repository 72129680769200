
import { Vue, Options } from 'vue-class-component';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Filters } from '@/store/modules/filters/media_filters';


@Options({
    props: {
        isMobileMenu: {
            type: Boolean,
            required: false,
            default: false,
        },
        isScrollable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
})
export default class ContentMenu extends Vue {
    // @ts-ignore
    store: Store<RootState>;
    filters!: Filters;
    isMobileMenu = false;
    isScrollable!: boolean;
    contentViewport = 0;

    @Watch('$store.getters.filters', { immediate: true, deep: true })
    onFiltersChange(): void {
        if (this.filters && this.filters.collapse) {
            const filterMenuContainer: HTMLDivElement = this.$refs.filterMenuContainer as HTMLDivElement;
            filterMenuContainer.style.position = 'unset';
            filterMenuContainer.style.top = '123px';
        }
    }

    mounted(): void {
        this.store = useStore();
        this.filters = this.store.getters.filters;

        const ps = new PerfectScrollbar('#filtermenu-container');
        this.contentViewport = window.innerHeight - 183;
        ps.update();
    }
}
