
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
    props: {
        meta: {
            type: Object,
            required: false,
        },
    }
})

export default class PageTitle extends Vue {
    title!: string;

    @Watch('meta', {immediate: true, deep: true})
    onPathChange(newVal: string): void {
        // @ts-ignore
        this.title = newVal.title;
    }
}
