
import { Options, Vue } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Accordion from '@/components/global/Accordion/Accordion.vue';

export interface itemProps {
  type: string;
  text: string;
  link: string;
  icon: string;
  iconTag: string;
  viewBox?: string;
  filterKey?: string;
  filterValue?: string;
}

@Options({
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        leftIcon: {
            type: String,
            required: false,
        },
    },
    components: {
        BaseIcon,
        Accordion,
    },
})
export default class BaseAccordion extends Vue {
  id!: string;
  items!: Array<itemProps>;
  defaultViewBox = '-1 -3 18 18';
  leftIcon!: string;
  lastElement = 0;

  mounted(): void {
      this.lastElement = Object.keys(this.items).length - 1;
  }

  toggleLabel(key: string): void {
      // @ts-ignore
      document.getElementById(key).style.display = 'block';
      // @ts-ignore
      document.getElementById(key + '-input').setAttribute('placeholder', '');
  }

  toggleAccordionIcon(): void {
      const accordionOff = document.getElementById(this.id + 'AccordionInactive');
      const accordionOn = document.getElementById(this.id + 'AccordionActive');
      if (accordionOff && accordionOn) {
          if (accordionOff.className.includes('active')) {
              accordionOff.classList.remove('active');
              accordionOff.style.display = 'none';
              accordionOn.classList.add('active');
              accordionOn.style.display = 'block';
          } else {
              accordionOff.classList.add('active');
              accordionOff.style.display = 'block';
              accordionOn.classList.remove('active');
              accordionOn.style.display = 'none';
          }
      }
  }
}
