
import { Vue, Options } from 'vue-class-component';
import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import { resetPayload } from './AuthInterfaces';
import { AxiosResponse } from 'axios';
import { tokenResPayload } from '@/store/modules/auth/index';
import Swal from 'sweetalert2';
import { setDefaultAlertOptions } from '@/components/Account/ChangePassword.vue';

@Options({
    components: {},
})
export default class ResetPasswordForm extends Vue {
  email = '';
  store!: Store<RootState>;

  mounted(): void {
      this.store = useStore();
  }
  async submitForm(): Promise<void> {
      const resetPasswordAlert = Swal.mixin(setDefaultAlertOptions());

      if (
          this.email === '' ||
      !this.email.includes('@') ||
      !this.email.includes('.')
      ) {
          await resetPasswordAlert.fire({
              title: 'Wrong input',
              text: 'An email needs an @ sign, and at least one dot.',
          });

          return;
      }
      this.$emit('update:loading', true);
      await this.resetPassword();
      this.$emit('update:loading', false);
  }

  async resetPassword(): Promise<void> {
      const resetPasswordAlert = Swal.mixin(setDefaultAlertOptions());
      const payload: resetPayload = {
          email: this.email,
      };
      const newPasswordResult: AxiosResponse<tokenResPayload> = await this.store.dispatch(
          'newPasswordRequest',
          payload
      );
      console.log(newPasswordResult);
      if (newPasswordResult.status === 200) {
          await resetPasswordAlert.fire({
              title: 'Check your email',
              text:
          'We have sent you an email with information on how to reset your password.',
          });
      }
  }
}
