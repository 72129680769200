<template>
  <path
    d="M0,8 C0,10.8581249 1.52479133,13.4991411 4,14.9282034 C6.4752086,16.3572658 9.5247914,16.3572658 12,14.9282034 C14.4752087,13.4991411 16,10.8581249 16,8 C16,5.14187537 14.4752087,2.50085918 12,1.07179679 C9.5247914,-0.357265597 6.4752086,-0.357265597 4,1.07179679 C1.52479133,2.50085918 0,5.14187537 0,8 L0,8 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M10.4347834,5.9130436 C10.4357395,4.70877412 9.55615898,3.68472095 8.36553827,3.50392214 C7.17491757,3.32312333 6.03098741,4.03990122 5.67440197,5.19016758 C5.31781652,6.34043394 5.85571451,7.57858276 6.93982609,8.10295664 L6.26086957,12.5217392 L9.73913043,12.5217392 L9.05878261,8.10295664 C9.89928612,7.69707259 10.4337828,6.84641759 10.4347834,5.9130436 L10.4347834,5.9130436 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
