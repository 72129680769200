import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "content-menu-list-item",
    ref: "listItem",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateMe()))
  }, [
    _renderSlot(_ctx.$slots, "list-item")
  ], 512))
}