
import { Vue, Options } from 'vue-class-component';
import { Image } from '@/interfaces/Media';
import userCollection from '@/services/UserCollectionService';
import { collection } from '@/interfaces/collection';
import BaseButton from '@/components/global/UI/BaseButton.vue';
import ColorButton from '@/components/global/UI/ColorButton.vue';
import ChildDialog from '@/components/global/UI/ChildDialog.vue';
import BaseInput from '@/components/global/UI/BaseInput.vue';

interface collection_with_exist_flag extends collection {
media_exists?: boolean;
}


@Options({
    props: {
        image: {
            type: Object,
            required: true,
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
        collections: {
            type: Array,
            required: false,
        }
    },
    emits: ['close', 'create-collection-submitted'],
    components: {
        BaseButton,
        ColorButton,
        ChildDialog,
        BaseInput
    }
})
export default class ThumbAddToCollectionModal extends Vue {
    image!: Image;
    collections: Array<collection_with_exist_flag> = [];
    enabled!: boolean;
    isMobile = false;
    addCollectionEnabled = true;
    createCollectionEnabled = false;
    title = '';
    errMessage = '';

    mounted(): void {
        this.isMobile = screen.width < 768;
        userCollection.view().then((res) => {
            const userCollections = res.data as Array<collection_with_exist_flag>;
            userCollections.forEach( collection => {
                collection.media_exists = false;
                if (collection.id) {
                    this.collections.push(collection);
                }
            });
        });
    }

    toggleCreateCollection(): void {
        this.createCollectionEnabled = true;
        this.addCollectionEnabled = false;
    }

    cancelCreate(): void {
        this.createCollectionEnabled = false;
        this.addCollectionEnabled = true;
    }

    toggleAddToCollection(state: boolean, collection_id: number, media_id: number): void {
        if (state) {
            userCollection.delete(collection_id, media_id).then((res) => {
                if (res.status === 200) {
                    this.setCollectionsMediaExist(this.collections, collection_id, false);
                }
            });
        } else {
            userCollection.add(collection_id, media_id).then((res) => {
                if (res.status === 200) {
                    this.setCollectionsMediaExist(this.collections, collection_id);
                }
            });
        }
    }

    setCollectionsMediaExist(collections: Array<collection_with_exist_flag>, collection_id: number, shouldExist = true): void {
        collections.forEach( col => {
            if(col.id === collection_id) {
                col.media_exists = shouldExist;
            }
        });
    }

    onSubmit(): void {
        if (this.title == '') {
            this.errMessage = 'Collection name is incomplete. Please fill out collection name field.';
            return;
        }

        this.$emit('create-collection-submitted', this.title);

        this.title = '';

        userCollection.create(this.title).then((res) => {
            if (res.status === 200) {
                const collection = res.data as collection;
                userCollection.add(collection.id, Number(this.image?.id)).then((res) => {
                    if (res.status === 200) {
                        if(this.collections) {
                            this.collections[collection.id].media_exists = true;
                        }
                    }
                });
                this.$emit('close');
            }
        });
    }

    closeMe(): void {
        this.$emit('close');
    }
}
