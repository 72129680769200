<template>
  <path
          d="M6.5,16.5l2.45-3.151a1,1,0,0,1,1.274-.206L12,14.5l1.663-2.14a1,1,0,0,1,1.587.067L18,16.5Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
  <path
          d="M6.500 9.000 A1.500 1.500 0 1 0 9.500 9.000 A1.500 1.500 0 1 0 6.500 9.000 Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
  <path
          d="M19.5,3.793a1,1,0,0,0-.707-.293H1.5a1,1,0,0,0-1,1v15a1,1,0,0,0,1,1h21a1,1,0,0,0,1-1V8.207a1,1,0,0,0-.293-.707Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
</template>
;
