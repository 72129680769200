
import { Vue, Options } from 'vue-class-component';
import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import router from '@/router';
import { loginPayload } from './AuthInterfaces';
import Swal from 'sweetalert2';
import { tokenResPayload } from '@/store/modules/auth/index';
import { AxiosResponse } from 'axios';
import { setDefaultAlertOptions } from '@/components/Account/ChangePassword.vue';

@Options({
    components: {},
})
export default class LoginForm extends Vue {
  email = '';
  password = '';
  passwordCheck = '';

  store!: Store<RootState>;

  mounted(): void {
      this.store = useStore();
  }
  async submitForm(): Promise<void> {
      if (!validateEmail(this.email)) {
          return;
      }

      if (!validatePassword(this.password)) {
          return;
      }
      this.$emit('update:loading', true);
      await this.loginUser();
      this.$emit('update:loading', false);
  }

  async loginUser(): Promise<void> {
      const payload: loginPayload = {
          email: this.email,
          password: this.password,
      };

      const loginAlert = Swal.mixin(setDefaultAlertOptions());

      return this.store.dispatch('login', payload).then(
          (authResult: AxiosResponse<tokenResPayload>) => {
              if (authResult.status === 200) {
                  router.push('/');
                  return;
              }
              if (authResult.status === 401) {
                  loginAlert.fire({
                      title: 'Incorrect login credentials',
                      text: 'Either your email or password is incorrect.',
                  });
                  return;
              }
          },
          () => {
              loginAlert.fire({
                  title: 'An error occurred',
                  text:
            'There was an error logging in, our development team has been notified.',
              });
              return;
          }
      );
  }
}

export function validateEmail(email: string): boolean {
    const loginAlert = Swal.mixin(setDefaultAlertOptions());

    if (email === '' || !email.includes('@') || !email.includes('.')) {
        loginAlert.fire({
            title: 'Email could not be validated',
            text: 'An email needs an @ sign, and at least one dot.',
        });
        return false;
    }
    return true;
}

export function validatePassword(password: string): boolean {
    const loginAlert = Swal.mixin(setDefaultAlertOptions());

    if (password.length < 6) {
        loginAlert.fire({
            title: 'Password could not be validated',
            text: 'Your password needs to be at least 6 characters long.',
        });
        return false;
    }
    return true;
}
