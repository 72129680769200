<template>
  <path
    d="M9.94860388,8.3907373 L10.0181743,8.4603077 C10.627567,9.06966925 11.6155547,9.06966925 12.2249474,8.4603077 L15.3180474,5.36442488 C16.2273175,4.44836438 16.2273175,2.97032572 15.3180474,2.05426522 L13.9461191,0.683032629 C13.0309238,-0.227677543 11.5518504,-0.227677543 10.6366551,0.683032629 L7.54285943,3.77613263 C6.93349788,4.38552532 6.93349788,5.37351305 7.54285943,5.98290574 L7.61242983,6.05247614"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M6.05266146,7.6101574 L5.98309106,7.540587 C5.37369837,6.93122545 4.38571064,6.93122545 3.77631795,7.540587 L0.682522245,10.6364698 C-0.227507415,11.5522172 -0.227507415,13.0308821 0.682522245,13.9466295 L2.05375484,15.3178621 C2.96887342,16.2294153 4.44879591,16.2294153 5.36391449,15.3178621 L8.4570145,12.2247621 C8.74998242,11.9323019 8.91461283,11.5353359 8.91461283,11.1213755 C8.91461283,10.7074152 8.74998242,10.3104492 8.4570145,10.017989 L8.3874441,9.94841856"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="4.9659718"
    y1="11.0351082"
    x2="11.0339021"
    y2="4.96578648"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
