<template>
  <path
    d="M0,8 C0,10.8581249 1.52479133,13.4991411 4,14.9282034 C6.4752086,16.3572658 9.5247914,16.3572658 12,14.9282034 C14.4752087,13.4991411 16,10.8581249 16,8 C16,5.14187537 14.4752087,2.50085918 12,1.07179679 C9.5247914,-0.357265597 6.4752086,-0.357265597 4,1.07179679 C1.52479133,2.50085918 0,5.14187537 0,8 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M8.49113043,3.77947838 L9.56173913,6.26295664 L11.6452174,6.26295664 C11.8605982,6.25511949 12.0577671,6.38317946 12.1381865,6.58313702 C12.2186059,6.78309458 12.165006,7.01200936 12.0041739,7.15547838 L10.1954783,8.76660881 L11.1972174,11.0713045 C11.2873607,11.2874487 11.2296508,11.5370067 11.0537676,11.6916328 C10.8778843,11.8462589 10.6229897,11.8715242 10.4201739,11.7544349 L8,10.390261 L5.57913043,11.7544349 C5.37621953,11.8731652 5.12009441,11.8487052 4.94332382,11.6937153 C4.76655324,11.5387253 4.70881739,11.2879965 4.8,11.0713045 L5.80382609,8.76730447 L3.99513043,7.15686968 C3.833278,7.01370576 3.7788971,6.78425164 3.85928308,6.58367693 C3.93966905,6.38310221 4.13746494,6.25471505 4.3533913,6.26295664 L6.44034783,6.26295664 L7.51234783,3.77947838 C7.60960882,3.59984484 7.79746503,3.48793324 8.00173913,3.48793324 C8.20601323,3.48793324 8.39386944,3.59984484 8.49113043,3.77947838 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
