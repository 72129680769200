import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_video_player = _resolveComponent("vue3-video-player")!

  return (_openBlock(), _createElementBlock("div", {
    class: "player-container thumbnail-video-player",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.play('video' + _ctx.index))),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.enablePause ? _ctx.pause() : ''))
  }, [
    _createVNode(_component_vue3_video_player, {
      ref: "videoPlayer",
      controls: _ctx.controls,
      muted: _ctx.muted,
      loop: _ctx.loop,
      src: _ctx.images[_ctx.index].preview_url,
      "view-core": _ctx.initPlayer.bind(null, 'video' + _ctx.index)
    }, null, 8, ["controls", "muted", "loop", "src", "view-core"])
  ], 32))
}