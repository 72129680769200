import { Media } from '@/interfaces/Media';

export class MediaView {
    type_original: Media;
    type_background_removed: Media;

    constructor() {
        this.type_original = new mediaProps();
        this.type_background_removed = new mediaProps();
    }

    clearMedia(): void {
        this.type_original = new mediaProps();
        this.type_background_removed = new mediaProps();
    }
}

export class mediaProps {
    id = '';
    type = '';
    key = '';
    thumbnail_url = '';
    preview_url = false;
    is_favorite = false;
    is_background_remove_disabled = false;
    downloadable_files = [
        {
            id: 0,
            size: '',
            width: 0,
            height: 0,
        }
    ]
}