import Vue from 'vue';

import IconAccount from '@/assets/icon/components/IconAccount.vue';
import IconAdd from '@/assets/icon/components/IconAdd.vue';
import IconArchive from '@/assets/icon/components/IconArchive.vue';
import IconAssistant from '@/assets/icon/components/IconAssistant.vue';
import IconAttach from '@/assets/icon/components/IconAttach.vue';
import IconAudio from '@/assets/icon/components/IconAudio.vue';
import IconCancel from '@/assets/icon/components/IconCancel.vue';
import IconClose from '@/assets/icon/components/IconClose.vue';
import IconConvert from '@/assets/icon/components/IconConvert.vue';
import IconCreditcard from '@/assets/icon/components/IconCreditcard.vue';
import IconDotsVertical from '@/assets/icon/components/IconDotsVertical.vue';
import IconDotsHorizontal from '@/assets/icon/components/IconDotsHorizontal.vue';
import IconDownload from '@/assets/icon/components/IconDownload.vue';
import IconDownloadWhite from '@/assets/icon/components/IconDownloadWhite.vue';
import IconEditor from '@/assets/icon/components/IconEditor.vue';
import IconExpand from '@/assets/icon/components/IconExpand.vue';
import IconFavorit from '@/assets/icon/components/IconFavorit.vue';
import IconFolder from '@/assets/icon/components/IconFolder.vue';
import IconIcons from '@/assets/icon/components/IconIcons.vue';
import IconIllustration from '@/assets/icon/components/IconIllustration.vue';
import IconImage from '@/assets/icon/components/IconImage.vue';
import IconLocation from '@/assets/icon/components/IconLocation.vue';
import IconLogout from '@/assets/icon/components/IconLogout.vue';
import IconNavigationMenu from '@/assets/icon/components/IconNavigationMenu.vue';
import IconPassword from '@/assets/icon/components/IconPassword.vue';
import IconPayments from '@/assets/icon/components/IconPayments.vue';
import IconPhoto from '@/assets/icon/components/IconPhoto.vue';
import IconPin from '@/assets/icon/components/IconPin.vue';
import IconPlugins from '@/assets/icon/components/IconPlugins.vue';
import IconRecent from '@/assets/icon/components/IconRecent.vue';
import IconRemove from '@/assets/icon/components/IconRemove.vue';
import IconSearch from '@/assets/icon/components/IconSearch.vue';
import IconSearchRemove from '@/assets/icon/components/IconSearchRemove.vue';
import IconSendWhite from '@/assets/icon/components/IconSendWhite.vue';
import IconSettings from '@/assets/icon/components/IconSettings.vue';
import IconSubFolders from '@/assets/icon/components/IconSubFolders.vue';
import IconSupport from '@/assets/icon/components/IconSupport.vue';
import IconTextMatch from '@/assets/icon/components/IconTextMatch.vue';
import IconTools from '@/assets/icon/components/IconTools.vue';
import IconVector from '@/assets/icon/components/IconVector.vue';
import IconVideo from '@/assets/icon/components/IconVideo.vue';
import IconOrientationAll from '@/assets/icon/components/IconOrientationAll.vue';
import IconOrientationLandscape from '@/assets/icon/components/IconOrientationLandscape.vue';
import IconOrientationHorizontal from '@/assets/icon/components/IconOrientationHorizontal.vue';
import IconOrientationSquare from '@/assets/icon/components/IconOrientationSquare.vue';
import IconPeopleAll from '@/assets/icon/components/IconPeopleAll.vue';
import IconPeopleWith from '@/assets/icon/components/IconPeopleWith.vue';
import IconPeopleWithout from '@/assets/icon/components/IconPeopleWithout.vue';
import IconSafeSearchOn from '@/assets/icon/components/IconSafeSearchOn.vue';
import IconSafeSearchOff from '@/assets/icon/components/IconSafeSearchOff.vue';

import IconColorAny from '@/assets/icon/components/IconColorAny.vue';
import IconColorBlack from '@/assets/icon/components/IconColorBlack.vue';
import IconColorWhite from '@/assets/icon/components/IconColorWhite.vue';
import IconColorRed from '@/assets/icon/components/IconColorRed.vue';
import IconColorOrange from '@/assets/icon/components/IconColorOrange.vue';
import IconColorYellow from '@/assets/icon/components/IconColorYellow.vue';
import IconColorGreen from '@/assets/icon/components/IconColorGreen.vue';
import IconColorBlue from '@/assets/icon/components/IconColorBlue.vue';

export function loadIcons(app: Vue.App): void {
    app.component('icon-account', IconAccount);
    app.component('icon-add', IconAdd);
    app.component('icon-archive', IconArchive);
    app.component('icon-assistant', IconAssistant);
    app.component('icon-attach', IconAttach);
    app.component('icon-audio', IconAudio);
    app.component('icon-cancel', IconCancel);
    app.component('icon-close', IconClose);
    app.component('icon-convert', IconConvert);
    app.component('icon-creditcard', IconCreditcard);
    app.component('icon-dots-vertical', IconDotsVertical);
    app.component('icon-dots-horizontal', IconDotsHorizontal);
    app.component('icon-download', IconDownload);
    app.component('icon-download-white', IconDownloadWhite);
    app.component('icon-editor', IconEditor);
    app.component('icon-expand', IconExpand);
    app.component('icon-favorit', IconFavorit);
    app.component('icon-folder', IconFolder);
    app.component('icon-icons', IconIcons);
    app.component('icon-illustration', IconIllustration);
    app.component('icon-image', IconImage);
    app.component('icon-location', IconLocation);
    app.component('icon-logout', IconLogout);
    app.component('icon-navigation-menu', IconNavigationMenu);
    app.component('icon-password', IconPassword);
    app.component('icon-payments', IconPayments);
    app.component('icon-photo', IconPhoto);
    app.component('icon-pin', IconPin);
    app.component('icon-plugins', IconPlugins);
    app.component('icon-recent', IconRecent);
    app.component('icon-remove', IconRemove);
    app.component('icon-search', IconSearch);
    app.component('icon-search-remove', IconSearchRemove);
    app.component('icon-send-white', IconSendWhite);
    app.component('icon-settings', IconSettings);
    app.component('icon-sub-folders', IconSubFolders);
    app.component('icon-support', IconSupport);
    app.component('icon-text-match', IconTextMatch);
    app.component('icon-tools', IconTools);
    app.component('icon-vector', IconVector);
    app.component('icon-video', IconVideo);
    app.component('icon-orientation-all', IconOrientationAll);
    app.component('icon-orientation-vertical', IconOrientationLandscape);
    app.component('icon-orientation-horizontal', IconOrientationHorizontal);
    app.component('icon-orientation-square', IconOrientationSquare);
    app.component('icon-people-all', IconPeopleAll);
    app.component('icon-people-without', IconPeopleWithout);
    app.component('icon-people-with', IconPeopleWith);
    app.component('icon-safesearch-on', IconSafeSearchOn);
    app.component('icon-safesearch-off', IconSafeSearchOff);

    app.component('icon-color-any', IconColorAny);
    app.component('icon-color-black', IconColorBlack);
    app.component('icon-color-white', IconColorWhite);
    app.component('icon-color-red', IconColorRed);
    app.component('icon-color-orange', IconColorOrange);
    app.component('icon-color-yellow', IconColorYellow);
    app.component('icon-color-green', IconColorGreen);
    app.component('icon-color-blue', IconColorBlue);
}
