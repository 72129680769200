import api from './api';
import { AxiosResponse } from 'axios';


export interface completeCheckoutRes {
  access_token: string;
  token_type: string;
  expires_in: number;
}


class checkoutService {
    completeCheckout(): Promise<AxiosResponse<completeCheckoutRes>> {
        return api.post('/checkout/complete');
    }

}

export default new checkoutService();
