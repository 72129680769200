import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bfb87b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    name: _ctx.name,
    class: _normalizeClass(_ctx.showText ? 'button-with-text' : '')
  }, [
    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
    (_ctx.showText)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["button-divider", _ctx.responsiveClass])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.showText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["button-text", _ctx.responsiveClass])
        }, [
          _renderSlot(_ctx.$slots, "text", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}