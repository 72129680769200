import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-488973a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "id", "name", "placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: _ctx.type,
      id: _ctx.id,
      name: _ctx.name,
      class: _normalizeClass(_ctx.mode),
      placeholder: _ctx.text,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 42, _hoisted_1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 64))
}