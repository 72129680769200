<template>
  <path
    d="M1.5,3.77v7.64A12.31,12.31,0,0,0,9.72,22.88l1.12.41a3.31,3.31,0,0,0,2.32,0l1.12-.41A12.31,12.31,0,0,0,22.5,11.41V3.77a1.53,1.53,0,0,0-.93-1.4A24.21,24.21,0,0,0,12,.5,24.21,24.21,0,0,0,2.43,2.37,1.53,1.53,0,0,0,1.5,3.77Z"
    style="
      fill: none;
      stroke: #000000;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
  ></path>
  <g>
    <rect
      x="7.5"
      y="8.5"
      width="9"
      height="8"
      rx="1"
      style="
        fill: none;
        stroke: #000000;
        stroke-linecap: round;
        stroke-linejoin: round;
      "
    ></rect>
    <circle
      cx="12"
      cy="12.5"
      r="1.25"
      style="
        fill: none;
        stroke: #000000;
        stroke-linecap: round;
        stroke-linejoin: round;
      "
    ></circle>
    <path
      d="M14.5,8.5v-2a2.5,2.5,0,0,0-5,0"
      style="
        fill: none;
        stroke: #000000;
        stroke-linecap: round;
        stroke-linejoin: round;
      "
    ></path>
  </g>
</template>
;
