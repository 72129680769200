
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        type: {
            type: String,
        },
        name: {
            type: String,
        },
        responsive: {
            type: Boolean,
        },
    },
})
export default class IconButton extends Vue {
  showText = false;
  type!: string;
  responsive!: boolean;
  responsiveClass = '';

  mounted(): void {
      if (this.type == 'withText') {
          this.showText = true;
      }
      if (this.responsive) {
          this.responsiveClass = 'responsive';
      }
  }
}
