<template>
  <path
    d="M8.69565217,7.82608696 C8.69565217,8.30633457 8.30633457,8.69565217 7.82608696,8.69565217 L0.869565217,8.69565217 C0.389317609,8.69565217 0,8.30633457 0,7.82608696 L0,0.869565217 C0,0.389317609 0.389317609,0 0.869565217,0 L7.82608696,0 C8.30633457,0 8.69565217,0.389317609 8.69565217,0.869565217 L8.69565217,7.82608696 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M20,7.82608696 C20,8.30633457 19.6106824,8.69565217 19.1304348,8.69565217 L12.173913,8.69565217 C11.6936654,8.69565217 11.3043478,8.30633457 11.3043478,7.82608696 L11.3043478,0.869565217 C11.3043478,0.389317609 11.6936654,0 12.173913,0 L19.1304348,0 C19.6106824,0 20,0.389317609 20,0.869565217 L20,7.82608696 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M8.69565217,19.1304348 C8.69565217,19.6106824 8.30633457,20 7.82608696,20 L0.869565217,20 C0.389317609,20 0,19.6106824 0,19.1304348 L0,12.173913 C0,11.6936654 0.389317609,11.3043478 0.869565217,11.3043478 L7.82608696,11.3043478 C8.30633457,11.3043478 8.69565217,11.6936654 8.69565217,12.173913 L8.69565217,19.1304348 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M20,19.1304348 C20,19.6106824 19.6106824,20 19.1304348,20 L12.173913,20 C11.6936654,20 11.3043478,19.6106824 11.3043478,19.1304348 L11.3043478,12.173913 C11.3043478,11.6936654 11.6936654,11.3043478 12.173913,11.3043478 L19.1304348,11.3043478 C19.6106824,11.3043478 20,11.6936654 20,12.173913 L20,19.1304348 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
