
import { Vue, Options } from 'vue-class-component';
import BaseAccordion, { itemProps } from '@/components/global/UI/BaseAccordion.vue';

    @Options({
        components: {
            BaseAccordion,
        },
    })

export default class SearchMenuDpi extends Vue {
        accordionItems: Array<itemProps> = [
            {
                type: 'input',
                text: 'Min. DPI',
                link: '',
                icon: '',
                iconTag: '',
                filterKey: 'mindpi',
            },
            {
                type: 'input',
                text: 'Max. DPI',
                link: '',
                icon: '',
                iconTag: '',
                filterKey: 'maxdpi',
            },
        ];
}
