
import { Vue, Options } from 'vue-class-component';
import BaseRangeSelector from '../global/UI/BaseRangeSelector.vue';
import Accordion from '@/components/global/Accordion/Accordion.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { Watch } from 'vue-property-decorator';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Dimensions, Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        BaseRangeSelector,
        Accordion,
        BaseIcon,
    },
    props: {
        dimensions: {
            type: Object,
            required: true,
        },
    },
})
export default class SearchMenuDimensions extends Vue {
  // @ts-ignore
  store: Store<RootState>;
  filters!: Filters;
  dimensions!: Dimensions;
  open = false;
  rangeDimensions = {
      widthMin: 0,
      widthMax: 0,
      heightMin: 0,
      heightMax: 0
  };

  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters && this.filters.close_all) {
          this.open = false;
      }
  }


  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
      this.rangeDimensions.widthMin = this.dimensions.width_min;
      this.rangeDimensions.widthMax = this.dimensions.width_max;
      this.rangeDimensions.heightMin = this.dimensions.height_min;
      this.rangeDimensions.heightMax = this.dimensions.height_max;
  }

  toggleAccordionIcon(): void {
      this.open = !this.open;
  }

  rangeBWidthChanged(b: number): void {
      this.rangeDimensions.widthMax = Number(b);
  }

  rangeBHeightChanged(b: number): void {
      this.rangeDimensions.heightMax = Number(b);
  }

  addFilters(): void {
      this.dimensions.enabled = true;
      this.dimensions.width_min = this.rangeDimensions.widthMin;
      this.dimensions.width_max = this.rangeDimensions.widthMax;
      this.dimensions.height_min = this.rangeDimensions.heightMin;
      this.dimensions.height_max = this.rangeDimensions.heightMax;
  }
}
