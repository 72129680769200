
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Image } from '@/interfaces/Media';
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import SearchQuickSearches from '@/components/Search/SearchQuickSearches.vue';
import ColcadeVue from '@/assets/colcade/vue-colcade/index';
import { mount } from 'mount-vue-component';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import SearchLoader from '@/components/Search/SearchLoader.vue';


@Options({
    props: {
        images: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: false,
        },
        isPublic: {
            type: Boolean,
            required: false,
        },
        isCollection: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFirstPage: {
            type: Boolean,
            required: false,
        },
        searchId: {
            type: Number,
            required: true,
        },
        pageId: {
            type: Number,
            required: true,
        },
        totalHits: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: false
        },
        appendKey: {
            type: Number,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Thumbnail,
        SearchQuickSearches,
        ColcadeVue,
        SearchLoader
    },
})
export default class ThumbCollection extends Vue {
  store!: Store<RootState>;
  images!: Array<Image>;
  index!: number;
  shownImages: Array<Image> = [];
  imagesLoaded = false;
  searchId!: number;
  pageId!: number;
  isFirstPage!: boolean;
  totalHits!: number;
  isPublic!: boolean;
  isCollection!: boolean;
  isLoading!: boolean;
  // @ts-ignore
  colcade: ColcadeVue = {};

  @Watch('images', { immediate: true, deep: true })
  onImagesChanged(val: Array<Image>, oldVal: Array<Image>): void {
      if (!oldVal) {
          this.shownImages = val;
      } else {
          if (this.isFirstPage) {
              window.scrollTo(0, 0);
              const masonryGrid: HTMLDivElement = this.$refs.masonryGrid as HTMLDivElement;
              if (this.totalHits === 0) {
                  masonryGrid.style.display = 'none';
              } else {
                  masonryGrid.style.removeProperty('display');
              }
              this.shownImages = val;
              this.colcade.update('masonryGrid');
          } else {
              let elems = Array<HTMLElement>();
              val.forEach((item, i) => {
                  const {vNode, destroy, el} = mount(Thumbnail, {
                      props: {
                          images: val,
                          media: item,
                          index: i,
                          searchId: this.searchId,
                          pageId: this.pageId,
                          mediaId: Number(item.id),
                          triggerNextLoad: item.trigger_next
                      }
                  });
                  elems.push(el);
              });
              this.colcade.append('masonryGrid', elems);
          }
      }
  }

  @Watch('appendKey', { immediate: true, deep: true })
  onAppendKeyChanged(val: number, oldVal: number): void {
      // AppendKey watcher added to make sure images are watched
  }

  mounted(): void {
      this.store = useStore();
      this.createGrid();
  }

  createGrid(): void {
      const grid = document.querySelector('.masonry-grid');
      this.colcade = new ColcadeVue();
      this.colcade.create({
          name: 'masonryGrid',
          el: grid,
          config: {
              columns: '.grid-col',
              items: '.grid-item'
          }
      });
  }
}
