
import { Vue, Options } from 'vue-class-component';
import ContentMenuItem from '@/components/global/ContentMenu/ContentMenuItem.vue';
import ContentMenu from '@/components/global/ContentMenu/ContentMenu.vue';

@Options({
    components: {
        ContentMenu,
        ContentMenuItem,
    },
})

export default class AccountMenu extends Vue {

}
