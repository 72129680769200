<template>
  <path
    d="M20,3.47826087 C20,3.95850848 19.6106824,4.34782609 19.1304348,4.34782609 L0.869565217,4.34782609 C0.389317609,4.34782609 0,3.95850848 0,3.47826087 L0,0.869565217 C0,0.389317609 0.389317609,8.8817842e-15 0.869565217,8.8817842e-15 L19.1304348,8.8817842e-15 C19.6106824,8.8817842e-15 20,0.389317609 20,0.869565217 L20,3.47826087 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M19.1304348,6.08695652 L19.1304348,14.7826087 C19.1304348,15.2628563 18.7411172,15.6521739 18.2608696,15.6521739 L1.73913043,15.6521739 C1.25888283,15.6521739 0.869565217,15.2628563 0.869565217,14.7826087 L0.869565217,6.08695652"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M13.0434783,8.26086957 C13.0434783,8.98124098 12.4595018,9.56521739 11.7391304,9.56521739 L8.26086957,9.56521739 C7.54049815,9.56521739 6.95652174,8.98124098 6.95652174,8.26086957 C6.95652174,7.54049815 7.54049815,6.95652174 8.26086957,6.95652174 L11.7391304,6.95652174 C12.4595018,6.95652174 13.0434783,7.54049815 13.0434783,8.26086957 L13.0434783,8.26086957 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
