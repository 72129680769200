
import { Vue, Options } from 'vue-class-component';
import SideBar from './components/Menu/SideBar/SideBar.vue';
import TopBar from './components/Menu/TopBar/TopBar.vue';
import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import AuthComponent from '@/components/global/Auth/Auth.vue';

@Options({
    components: {
        TopBar,
        SideBar,
        AuthComponent,
    },
})
export default class App extends Vue {
  displayMenuOverlay = false;
  store!: Store<RootState>;

  mounted(): void {
      this.store = useStore();
      history.scrollRestoration = 'auto';
  }

  toggleBackgroundOverlay(displayOverlay: boolean): void {
      this.displayMenuOverlay = displayOverlay;
  }
}
