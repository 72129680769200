import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { Filters } from './media_filters';


@Module
export default class filters extends VuexModule {
    SearchKeyword = '';
    Filters!: Filters;

    constructor() {
        super(VuexModule);
        this.Filters = new Filters();
    }

    @Mutation
    setFilters(filters: Filters): void {
        this.Filters = filters;
        localStorage.setItem('filters', JSON.stringify(filters));
    }

    get filters(): Filters {
        return this.Filters; 
    }

}