<template>
  <line
    x1="3.47826087"
    y1="6.95652174"
    x2="11.7391304"
    y2="6.95652174"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="3.47826087"
    y1="9.56521739"
    x2="8.69565217"
    y2="9.56521739"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="3.47826087"
    y1="4.34782609"
    x2="9.56521739"
    y2="4.34782609"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="3.47826087"
    y1="12.173913"
    x2="6.95652174"
    y2="12.173913"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="3.47826087"
    y1="14.7826087"
    x2="6.95652174"
    y2="14.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <path
    d="M8.69565217,20 L0.869565217,20 C0.389317609,20 0,19.6106824 0,19.1304348 L0,0.869565217 C0,0.389317609 0.389317609,0 0.869565217,0 L12.4286957,0 C12.6592998,0 12.8804411,0.0916961958 13.0434783,0.254782609 L16.2669565,3.47826087 C16.4300429,3.64129803 16.5217391,3.86243929 16.5217391,4.09304348 L16.5217391,6.95652174"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M9.56521739,14.7826087 C9.56521739,17.6640943 11.901123,20 14.7826087,20 C17.6640943,20 20,17.6640943 20,14.7826087 C20,11.901123 17.6640943,9.56521739 14.7826087,9.56521739 C11.901123,9.56521739 9.56521739,11.901123 9.56521739,14.7826087 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M17.1078261,13.266087 L14.5817391,16.633913 C14.4680505,16.7849341 14.2945714,16.8793688 14.1060239,16.8928722 C13.9174765,16.9063756 13.7323105,16.8376264 13.5982609,16.7043478 L12.293913,15.4"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
