import Vuex, { StoreOptions } from 'vuex';
import auth from './modules/auth/index';
import filters from './modules/filters/index';
import media from './modules/media/index';

import { RootState } from './types';

const store: StoreOptions<RootState> = {
    modules: {
        auth,
        filters,
        media
    },
};

// Now using: https://github.com/championswimmer/vuex-module-decorators 
// for everything.!
export default new Vuex.Store(store);
