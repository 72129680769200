<template>
  <path
    d="M0.000 7.000 A7.000 7.000 0 1 0 14.000 7.000 A7.000 7.000 0 1 0 0.000 7.000 Z"
    fill="#000000"
    stroke="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="0"
  ></path>
</template>
;
