import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_accordion = _resolveComponent("base-accordion")!

  return (_openBlock(), _createBlock(_component_base_accordion, {
    id: "dpi",
    title: "DPI",
    items: _ctx.accordionItems
  }, null, 8, ["items"]))
}