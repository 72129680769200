
import { Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { RootState } from '@/store/types';
import { Filters } from '@/store/modules/filters/media_filters';

export default class SelectedFilterClear extends Vue {
    store!: Store<RootState>;
    filters!: Filters;

    mounted(): void {
        this.store = useStore();
        this.filters = this.store.getters.filters;
    }
}
