import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25dadf2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item-icon" }
const _hoisted_2 = { class: "list-item-text" }
const _hoisted_3 = {
  key: 0,
  class: "amount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_photo = _resolveComponent("icon-photo")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_icon_illustration = _resolveComponent("icon-illustration")!
  const _component_icon_vector = _resolveComponent("icon-vector")!
  const _component_icon_video = _resolveComponent("icon-video")!
  const _component_icon_icons = _resolveComponent("icon-icons")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.filterEnabled === true ? 'filter-item-selected' : 'filter-item')
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.iconName === 'photo')
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 0,
            width: "22",
            height: "22",
            "icon-name": "{{ iconName }}",
            viewBox: "-0.6 -0.5 24 24"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_photo)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.iconName === 'illustration')
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 1,
            width: "22",
            height: "22",
            "icon-name": "{{ iconName }}",
            viewBox: "-0.6 -0.5 19 19"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_illustration)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.iconName === 'vector')
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 2,
            width: "22",
            height: "22",
            "icon-name": "{{ iconName }}",
            viewBox: "-0.6 -0.5 19 19"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_vector)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.iconName === 'video')
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 3,
            width: "22",
            height: "22",
            "icon-name": "{{ iconName }}",
            viewBox: "-0.6 -0.5 19 19"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_video)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.iconName === 'icon')
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 4,
            width: "22",
            height: "22",
            "icon-name": "{{ iconName }}",
            viewBox: "-0.6 -0.7 19 19"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_icons)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
      (_ctx.amount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(_ctx.amount) + ") ", 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}