
import { Options, Vue } from 'vue-class-component';
import { Image } from '@/interfaces/Media';

@Options({
    props: {
        images: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        controls: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        enablePause: {
            type: Boolean,
            default: false,
        },
    },
})

export default class VideoPlayer extends Vue {
    images!: Array<Image>;
    index!: number;
    player!: HTMLMediaElement;
    controls!: boolean;
    muted!: boolean;
    loop!: boolean;
    enablePause!: boolean;

    play(): void {
        this.player.play();
    }

    pause(): void {
        this.player.pause();
        this.player.load();
    }

    initPlayer(id: string, player: HTMLMediaElement): void {
        this.player = player;
    }
}
