<template>
  <path
    d="M15.5684564,10.1702785 L10.2607119,15.478023 L7.65205632,15.9997541 L8.17378743,13.3910986 L13.481532,8.08335404 C13.7573866,7.80706474 14.1317868,7.65181044 14.5222116,7.65181044 C14.9126365,7.65181044 15.2870366,7.80706474 15.5628913,8.08335404 L15.5684564,8.08891917 C15.8447457,8.36477382 16,8.73917398 16,9.12959883 C16,9.52002368 15.8447457,9.89442384 15.5684564,10.1702785 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M5.56513187,15.9997541 L0.695641484,15.9997541 C0.311449301,15.9997541 0,15.6883048 0,15.3041126 L0,0.695641484 C0,0.311449301 0.311449301,7.28306304e-14 0.695641484,7.28306304e-14 L9.94280372,7.28306304e-14 C10.1272842,7.28306304e-14 10.3041945,0.0733558293 10.4346223,0.203822955 L13.0133652,2.78256593 C13.1438324,2.91299366 13.2171882,3.08990395 13.2171882,3.27438446 L13.2171882,5.56513187"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
