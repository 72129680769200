
import { Vue, Options } from 'vue-class-component';
import { Image } from '@/interfaces/Media';

// Import editor settings
import {
    getEditorDefaults,

    // Import the default image reader and writer
    createDefaultImageReader,
    createDefaultImageWriter,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_finetune,
    plugin_annotate,
    plugin_filter,
    plugin_sticker,
    plugin_decorate,
    plugin_resize,

    // The user interface and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_finetune_locale_en_gb,
    plugin_annotate_locale_en_gb,
    plugin_filter_locale_en_gb,
    plugin_sticker_locale_en_gb,
    plugin_decorate_locale_en_gb,
    plugin_resize_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale
    markup_editor_locale_en_gb,

    // Import the default configuration for plugins
    markup_editor_defaults,
    plugin_finetune_defaults,
    plugin_filter_defaults,
} from 'doka';
import DokaDialog from './DokaDialog.vue';
// @ts-ignore
import { DokaImageEditor } from 'vue-doka';

setPlugins(
    plugin_crop,
    plugin_finetune,
    plugin_filter,
    plugin_annotate,
    plugin_sticker,
    plugin_decorate,
    plugin_resize
);

@Options({
    props: {
        image: {
            type: Object,
            required: true,
        },
        enabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        height: {
            type: Number,
            required: true,
        },
    },
    components: {
        DokaImageEditor,
        DokaDialog,
    },
    emits: ['close'],
})
export default class EditModal extends Vue {
  image!: Image;
  enabled!: boolean;

  src = '';
  editorDefaults = getEditorDefaults({
      // This will read the image data (required)
      imageReader: createDefaultImageReader(),

      // This will write the output image
      imageWriter: createDefaultImageWriter(),

      // The markup editor default options, tools, shape style controls
      ...markup_editor_defaults,

      // The finetune util controls
      ...plugin_finetune_defaults,
      ...plugin_filter_defaults,

      // The icons and labels to use in the user interface (required)
      locale: {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...plugin_filter_locale_en_gb,
          ...plugin_sticker_locale_en_gb,
          ...plugin_decorate_locale_en_gb,
          ...plugin_resize_locale_en_gb,
          ...markup_editor_locale_en_gb,
      },

      cropEnableInfoIndicator: true,
      cropSelectPresetOptions: [
          [
              'Crop Aspect Ratios',
              [
                  [undefined, 'Custom'],
                  [1, 'Square'],
                  [4 / 3, 'Landscape'],
                  [3 / 4, 'Portrait'],
              ],
          ],
      ],

      stickers: [
          [
              'Shapes',
              [
                  '🔴',
                  '🟠',
                  '🟡',
                  '🟢',
                  '🔵',
                  '🟣',
                  '🟤',
                  '�',
                  '🔻',
                  '🔸',
                  '🔹',
                  '🔶',
                  '🔷',
                  '🔳',
                  '🔲',
                  '🟥',
                  '🟧',
                  '🟨',
                  '🟩',
                  '🟦',
                  '🟪',
                  '🟫',
              ],
          ],
          [
              'Emoji',
              [
                  '⭐️',
                  '😄',
                  '👍',
                  '👎',
                  '☀️',
                  '🌤',
                  '❤️',
                  '❗️',
                  '❓',
                  '❌',
                  '🚫',
                  '💯',
              ],
          ],
      ],
  });

  mounted(): void {
      this.src = this.image.thumbnail_url;
  }
}
