
import IconIcons from '@/assets/icon/components/IconIcons.vue';
import IconIllustration from '@/assets/icon/components/IconIllustration.vue';
import IconVector from '@/assets/icon/components/IconVector.vue';
import IconVideo from '@/assets/icon/components/IconVideo.vue';
import { Vue, Options } from 'vue-class-component';
import SearchMenuHeader from './SearchMenuHeader.vue';
import SearchMenuMediaType from './SearchMenuMediaType.vue';
import SearchMenuMediaTypeItem from './SearchMenuMediaTypeItem.vue';
import SearchMenuRelated from './SearchMenuRelated.vue';
import SearchMenuOrientation from './SearchMenuOrientation.vue';
import SearchMenuColorFilter from './SearchMenuColorfilter.vue';
import SearchMenuPeople from './SearchMenuPeople.vue';
import SearchMenuSafeSearch from './SearchMenuSafeSearch.vue';
import SearchMenuDimensions from './SearchMenuDimensions.vue';
import SearchMenuDpi from './SearchMenuDpi.vue';
import SearchMenuDuration from './SearchMenuDuration.vue';
import ContentMenu from '@/components/global/ContentMenu/ContentMenu.vue';
import ContentMenuItem from '@/components/global/ContentMenu/ContentMenuItem.vue';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        SearchMenuHeader,
        SearchMenuMediaTypeItem,
        SearchMenuMediaType,
        SearchMenuRelated,
        SearchMenuOrientation,
        SearchMenuColorFilter,
        SearchMenuPeople,
        SearchMenuSafeSearch,
        SearchMenuDimensions,
        SearchMenuDuration,
        SearchMenuDpi,
        IconIllustration,
        IconVector,
        IconVideo,
        IconIcons,
        ContentMenu,
        ContentMenuItem,
        BaseIcon,
    }
})
export default class SearchMenu extends Vue {
  store!: Store<RootState>;
  filters!: Filters;
  isMounted = false;


  created(): void {
      this.store = useStore();
  }

  mounted(): void {
      this.filters = this.store.getters.filters;
      this.isMounted = true;
  }
}
