import { searchQuery, filters as sqFilters } from '@/services/searchService';
import { FilterMeta } from '@/interfaces/Filter';
// Should be rewritten to look exactly like what the api requires.
export class Filters {
    search_string: string;
    media_types: Array<FilterMeta>;
    orientations: Orientations;
    safesearch: Safesearch;
    people: People;
    color: Color;
    duration: Duration;
    dimensions: Dimensions;
    close_all!: boolean;
    collapse!: boolean;
    search_input: string;
    constructor() {
        this.search_string = '';
        this.media_types = [
            new Media_type('PHOTO', 'Photos', true),
            new Media_type('ILLUSTRATION', 'Illustrations'),
            new Media_type('VECTOR', 'Vectors'),
            new Media_type('VIDEO', 'Videos'),
            new Media_type('ICON', 'Icons'),
        ];
        this.orientations = new Orientations(
            new Orientation('Horizontal', 'icon-orientation-horizontal'),
            new Orientation('Vertical', 'icon-orientation-vertical'),
            new Orientation('Square', 'icon-orientation-square'),
        );
        this.safesearch = new Safesearch();
        this.people = new People();
        this.color = new Color();
        this.duration = new Duration(1, 400);
        this.dimensions = new Dimensions('Dimensions', 1, 4800, 1, 4800);
        this.close_all = false;
        this.collapse = false;
        this.search_input = '';
    }

    MediaTypesHasDuration(): boolean {
        let hasDuration = false;
        this.media_types.forEach(mediaType => {
            if (mediaType.name === 'VIDEO' && mediaType.enabled) {
                hasDuration = true;
            }
        });
        if (!hasDuration) {
            this.duration.enabled = false;
        }
        return hasDuration;
    }

    formatForApi(): searchQuery {
        const filters: sqFilters = {
            content_moderation: this.safesearch.enabled,
        };

        if (this.dimensions.enabled) {
            filters.width = this.dimensions.formatWidth();
            filters.height = this.dimensions.formatHeight();
        }

        this.orientations.enabled() ? filters.media_orientations = this.formatMediaOrientations() : '';
        this.MediaTypesHasDuration() && this.duration.enabled ? filters.duration = this.formatDuration() : '';
        this.people.enabled ? filters.people = this.formatPeople() : '';
        this.media_types.length > 0 ? filters.media_types = this.formatMediaTypes() : '';
        this.color.enabled ? filters.color = this.color.value : '';

        return {
            search_for: this.search_string,
            filters: filters
        };
    }

    formatMediaOrientations(): Array<string> {
        const orientations: Array<string> = [];

        if (this.orientations.horizontal.enabled) {
            orientations.push(this.orientations.horizontal.name);
        }
        if (this.orientations.vertical.enabled) {
            orientations.push(this.orientations.vertical.name);
        }
        if (this.orientations.square.enabled) {
            orientations.push(this.orientations.square.name);
        }

        return orientations;
    }

    formatDuration(): {
        min: number;
        max: number;
        } {
        return {
            min: this.duration.duration_min,
            max: this.duration.duration_max,
        };
    }

    formatPeople(): {
        include: boolean;
        min: number;
        max: number;
        } {
        return {
            include: this.people.enabled,
            min: 1,
            max: 10,
        };
    }


    formatMediaTypes(): Array<string> {
        const media_types: Array<string> = [];
        this.media_types.forEach(media_type => {
            if (media_type.enabled) {
                media_types.push(media_type.name);
            }
        });

        if (media_types.length === 0) {
            media_types.push('PHOTOS');
        }
        return media_types;
    }

    clearFilters(): void {
        this.close_all = true;
        this.media_types[1].enabled = false;
        this.media_types[2].enabled = false;
        this.media_types[3].enabled = false;
        this.media_types[4].enabled = false;
        this.orientations.horizontal.enabled = false;
        this.orientations.vertical.enabled = false;
        this.orientations.square.enabled = false;
        this.color.enabled = false;
        this.people.enabled = false;
        this.dimensions.enabled = false;
        this.safesearch.value = false;
        this.duration.enabled = false;
        this.people.value = '';

        setTimeout(() => {
            this.close_all = false;
        }, 2000);
    }
}


export class Dimensions {
    name: string;
    enabled = false;
    width_min: number;
    width_max: number;
    height_min: number;
    height_max: number;
    constructor(name: string,
        width_min: number,
        width_max: number,
        height_min: number,
        height_max: number,
    ) {
        this.name = name;
        this.width_min = width_min;
        this.width_max = width_max;
        this.height_min = height_min;
        this.height_max = height_max;
    }

    formatWidth(): {
        min: number;
        max: number;
        } {
        return {
            min: this.width_min,
            max: this.width_max,
        };
    }

    formatHeight(): {
        min: number;
        max: number;
        } {
        return {
            min: this.height_min,
            max: this.height_max,
        };
    }
}

export class Media_type {
    name: string;
    display: string;
    enabled = true;
    total_hits = 0;

    constructor(name: string, display: string, enabled = false) {
        this.name = name;
        this.display = display;
        this.enabled = enabled;
    }
}


export class Orientations {
    horizontal: Orientation;
    vertical: Orientation;
    square: Orientation;
    constructor(Horizontal: Orientation, Vertical: Orientation, Square: Orientation) {
        this.horizontal = Horizontal;
        this.vertical = Vertical;
        this.square = Square;
    }

    enabled(): boolean {
        return this.horizontal.enabled || this.vertical.enabled || this.square.enabled;
    }
}

export class Orientation {
    name: string;
    value: string;
    iconTag = '';
    viewBox = '-1 -2 26 26';
    enabled = false;

    constructor(name: string, iconTag: string, value = '') {
        this.name = name;
        this.iconTag = iconTag;
        this.value = value;
    }
}


export class Safesearch {
    name = 'Safesearch';
    value = false;
    enabled = false;
}

export class People {
    name = 'People';
    value = '';
    enabled = false;
}

export class Color {
    name = 'Colors';
    value = {
        hue: 0,
        saturation: 0,
        luminosity: 0,
    };
    enabled = false;
    toHex(): string {
        return hslToHex(this.value.hue, this.value.saturation, this.value.luminosity);
    }

}

function hslToHex(h: number, s: number, l: number) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x: number) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export class Duration {
    name = 'Duration_min';
    duration_min: number;
    duration_max: number;
    enabled = false;
    // Refactor value out, as not needed
    value = 0;

    constructor(duration_min: number, duration_max: number) {
        this.duration_min = duration_min;
        this.duration_max = duration_max;
    }
}
