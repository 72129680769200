import Colcade from 'colcade';

const ColcadeWrapper = function $ColcadeWrapper(obj) {
    this.data = obj;

    this.init = () => {
        this.colc = this.createGrid();
    };
    this.createGrid = () => new Colcade(this.data.el, this.data.config);
    this.appendGrid = item => new Promise((resolve) => {
        this.colc.append(item);
        this.colc.layout();
    });
    this.prependGrid = item => this.colc.prepend(item);
    this.destroyGrid = () => new Promise((resolve) => {
        this.colc.destroy();
        this.colc = null;
        delete this.colc;
        resolve();
    });

    this.init();
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default function ColcadeFactory() {}
ColcadeFactory.prototype = {
    create: function $create(obj) {
        this[obj.name] = !this[obj.name] && new ColcadeWrapper(obj);
    },
    destroy: function $destroy(name) {
        if (Object.prototype.hasOwnProperty.call(this, name)) {
            this[name].destroyGrid();
            this[name] = null;
            delete this[name];
        } else {
            throw new Error(`${name} is not a property of $colcade`);
        }
    },
    update: function $update(name) {
        if (Object.prototype.hasOwnProperty.call(this, name)) {
            this[name].destroyGrid().then(() => {
                this[name].init();
            });
        } else {
            throw new Error(`${name} is not a property of $colcade`);
        }
    },
    append: function $append(name, items) {
        if (Object.prototype.hasOwnProperty.call(this, name)) {
            this[name].appendGrid(items);
        } else {
            throw new Error(`${name} is not a property of $colcade`);
        }
    }
};
