
import { Options, Vue } from 'vue-class-component';
import IconButton from '../UI/IconButton.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Tooltip from '../UI/Tooltip.vue';
import { tooltip } from './ThumbModal.vue';

@Options({
    props: {
        backgroundRemoveDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disableBgRemoveButton: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: Object,
            required: true,
        },
    },
    components: {
        IconButton,
        BaseIcon,
        Tooltip,
    },
    emits: ['removeBackground']
})
export default class RemoveBackgroundHelper extends Vue {
    isMobile = false;
    backgroundRemoveDisabled!: boolean;
    disableBgRemoveButton!: boolean;
    tooltip!: tooltip;

    initRemoveBackground(): void {
        if (!this.disableBgRemoveButton) {
            this.$emit('removeBackground');
        }
    }
}
