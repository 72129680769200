
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        related: {
            type: Array,
            required: true,
        },
    },
})
export default class SearchMenuRelated extends Vue {
    related: Array<string> = [];
}
