
import { Vue, Options } from 'vue-class-component';

    @Options({
        props: {
            title: {
                type: String,
                required: false,
            },
            isFullwidth: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        emits: ['close'],
    })

export default class BaseDialog extends Vue {
    isFullwidth = false;
}
