<template>
  <path
    d="M7 16.999L17 6.999"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M17 16.999L7 6.999"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M0.500 11.999 A11.500 11.500 0 1 0 23.500 11.999 A11.500 11.500 0 1 0 0.500 11.999 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
