import { initPayload, loginPayload, refreshPayload, resetPayload, updatePasswordPayload } from '@/components/global/Auth/AuthInterfaces';
import api, { apiError } from '@/services/api';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import jwt_decode from 'jwt-decode';
import { completeCheckoutRes } from '@/services/checkoutService';


const conf: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
};


axios.interceptors.response.use(response => {
    return response;
}, (error: AxiosError) => {
    return new Promise((resolve) => {
        resolve({
            data: error.response?.data,
            status: error.response?.status,
            statusText: error.response?.statusText,
            headers: {},
            config: {},
        });
    });
});

interface decodedToken {
    iss: string,
    iat: number,
    exp: number,
    nbf: number,
    jti: string,
    sub: string,
    prv: string,
    first_name: string,
    last_name: string,
    full_name: string,
    is_organization_owner: boolean,
    is_admin: boolean,
    checkout_status: string
}

export interface tokenResPayload {
    access_token?: string;
    token_type?: string;
    expires_in?: number;
    hosted_page?: string;
    message?: string;
    errors?: Array<string>;
}

export interface logoutResPayload {
    hosted_page?: string;
    message?: string;
    errors?: Array<string>;
}

@Module
export default class auth extends VuexModule {
    activeToken = ''
    activeTokenExpires!: number;

    @Mutation
    setToken(payload: AxiosResponse<tokenResPayload>): void {
        if (payload.status === 200) {
            this.activeToken = payload.data?.access_token as string;
            localStorage.setItem('token', payload.data?.access_token as string);
            if (payload.data?.expires_in) {
                this.activeTokenExpires = Math.floor(Date.now() / 1000) + payload.data?.expires_in;
            }
            localStorage.setItem('expires', '' + this.activeTokenExpires);
        }
    }

    @Mutation
    destroyToken(payload: AxiosResponse<logoutResPayload>): void {
        if (payload.status === 200) {
            this.activeToken = '';
            localStorage.setItem('token', '');
        }
    }

    // ToDo:
    // Not an action that needs the vuex store, move out of this class.!
    @Action({})
    async newPasswordRequest(
        payload: resetPayload
    ): Promise<AxiosResponse<tokenResPayload>> {
        const res: AxiosResponse<tokenResPayload> = await axios.post(
            'https://api.jumpstory.local/api/v1/auth/password/request',
            payload,
            conf
        );
        return res;
    }

    // ToDo:
    // Not an action that needs the vuex store, move out of this class.!
    @Action({})
    async setNewPassword(
        payload: updatePasswordPayload
    ): Promise<AxiosResponse<tokenResPayload>> {
        const res: AxiosResponse<tokenResPayload> = await axios.post(
            'https://api.jumpstory.local/api/v1/auth/password/update',
            payload,
            conf
        );
        return res;
    }


    @Action({ commit: 'setToken' })
    async refreshToken(
        payload: refreshPayload
    ): Promise<AxiosResponse<tokenResPayload>> {
        conf.headers['Authorization'] = 'Bearer ' + payload.refresh_token;
        const res: AxiosResponse<tokenResPayload> = await axios.post(
            'https://' + process.env.VUE_APP_API_URL + '/api/v1/auth/refresh',
            {},
            conf
        );
        return res;
    }

    @Action({ commit: 'setToken' })
    async completeCheckout(
        payload: AxiosResponse<completeCheckoutRes>
    ): Promise<AxiosResponse<tokenResPayload>> {
        // completeCheckoutRes overlaps completely with tokenResPayload, so no need for conversion.!
        return payload;
    }

    @Action({ commit: 'setToken' })
    async init(
        payload: initPayload
    ): Promise<AxiosResponse<tokenResPayload | apiError>> {
        try {
            const res: AxiosResponse<tokenResPayload> = await axios.post(
                'https://' + process.env.VUE_APP_API_URL + '/api/v1/checkout/init',
                payload,
                conf
            );
            return res;
        } catch (err) {
            return new Promise((resolve) => {
                resolve({
                    data: {},
                    status: 400,
                    statusText: 'No connection',
                    headers: {},
                    config: {},
                });
            });
        }
    }

    @Action({ commit: 'setToken' })
    async login(
        payload: loginPayload
    ): Promise<AxiosResponse<tokenResPayload>> {
        return axios.post(
            'https://' + process.env.VUE_APP_API_URL + '/api/v1/auth/login',
            payload,
            conf
        ).then((res: AxiosResponse<tokenResPayload>) => {
            return res;
        });
    }

    @Action({ commit: 'destroyToken' })
    async logout(): Promise<AxiosResponse<logoutResPayload>> {
        return api.post(
            'https://' + process.env.VUE_APP_API_URL + '/api/v1/auth/logout',
            conf
        ).then((res: AxiosResponse<logoutResPayload>) => {
            return res;
        });
    }


    get token(): string {
        if (this.activeToken !== '') {
            return this.activeToken;
        }
        const token = localStorage.getItem('token');
        if (token) {
            return token;
        }
        return '';
    }

    get ownId(): string {
        const decodedToken: decodedToken = jwt_decode(this.token);
        return decodedToken.sub;
    }

    get expires(): string {
        if (this.activeTokenExpires) {
            return '' + this.activeTokenExpires;
        }
        const expires = localStorage.getItem('expires');
        if (expires) {
            return expires;
        }
        return '';
    }

    get loggedIn(): boolean {
        const expires = +this.expires;
        if(this.token === '' || !expires || expires < Date.now() / 1000) {
            return false;
        }
        return true;
    }
}