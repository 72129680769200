<template>
  <path
    d="M15.9859403,7.79048264 C16.0277444,7.5856857 15.9752366,7.3729462 15.8429433,7.21111956 C15.71065,7.04929292 15.5125973,6.95553635 15.3035775,6.95578807 L6.74795815,6.95578807 C6.41711011,6.95618639 6.13176475,6.72348886 6.06559534,6.39932502 L5.87222443,5.42551469 C5.80605501,5.10135086 5.52070966,4.86865332 5.18986162,4.86905114 L0.696422529,4.86905114 C0.487402652,4.86879993 0.289349952,4.9625565 0.157056675,5.12438314 C0.0247633969,5.28620978 -0.0277444374,5.49894928 0.0140597199,5.70374622 L1.82256462,14.7462707 C1.88873403,15.0704345 2.17407939,15.3031321 2.50492743,15.3027343 L13.9124199,15.3027343 C14.2430066,15.3027343 14.5279697,15.0701783 14.5940871,14.7462707 C14.8813611,13.3092049 15.6464978,9.48560819 15.9859403,7.79048264 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M3.47873776,3.47789403 L3.47873776,0.695578807 C3.47873776,0.311421239 3.790159,1.77635684e-15 4.17431656,1.77635684e-15 L13.2168411,1.77635684e-15 C13.6009986,1.77635684e-15 13.9124199,0.311421239 13.9124199,0.695578807 L13.9124199,5.56463045"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="5.56547418"
    y1="2.08673642"
    x2="11.477894"
    y2="2.08673642"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="8.3477894"
    y1="4.17347284"
    x2="11.477894"
    y2="4.17347284"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
