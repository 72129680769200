import api from './api';
import { AxiosResponse } from 'axios';

export interface userRes {
  id: string;
  checkout_status: string;
  is_organization_owner: boolean;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface changePasswordReq {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

interface messageRes {
  message: string;
}

class userService {

    get(id: number): Promise<AxiosResponse<userRes>> {
        return api.get('user/' + id);
    }

    changePassword(
        id: number,
        cpr: changePasswordReq
    ): Promise<AxiosResponse<messageRes>> {
        return api.put('user/' + id + '/change-password', cpr);
    }
}

export default new userService();
