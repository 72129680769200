import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_menu_header = _resolveComponent("search-menu-header")!
  const _component_search_menu_media_type = _resolveComponent("search-menu-media-type")!
  const _component_content_menu_item = _resolveComponent("content-menu-item")!
  const _component_search_menu_orientation = _resolveComponent("search-menu-orientation")!
  const _component_search_menu_color_filter = _resolveComponent("search-menu-color-filter")!
  const _component_search_menu_people = _resolveComponent("search-menu-people")!
  const _component_search_menu_safe_search = _resolveComponent("search-menu-safe-search")!
  const _component_search_menu_dimensions = _resolveComponent("search-menu-dimensions")!
  const _component_search_menu_duration = _resolveComponent("search-menu-duration")!
  const _component_content_menu = _resolveComponent("content-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_search_menu_header),
    (_ctx.isMounted)
      ? (_openBlock(), _createBlock(_component_content_menu, {
          key: 0,
          isScrollable: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_content_menu_item, null, {
              "list-item": _withCtx(() => [
                _createVNode(_component_search_menu_media_type)
              ]),
              _: 1
            }),
            (_ctx.filters.orientations)
              ? (_openBlock(), _createBlock(_component_content_menu_item, { key: 0 }, {
                  "list-item": _withCtx(() => [
                    _createVNode(_component_search_menu_orientation, {
                      orientations: _ctx.filters.orientations
                    }, null, 8, ["orientations"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_content_menu_item, null, {
              "list-item": _withCtx(() => [
                _createVNode(_component_search_menu_color_filter, {
                  colorObject: _ctx.filters.color
                }, null, 8, ["colorObject"])
              ]),
              _: 1
            }),
            _createVNode(_component_content_menu_item, null, {
              "list-item": _withCtx(() => [
                _createVNode(_component_search_menu_people, {
                  people: _ctx.filters.people
                }, null, 8, ["people"])
              ]),
              _: 1
            }),
            _createVNode(_component_content_menu_item, null, {
              "list-item": _withCtx(() => [
                _createVNode(_component_search_menu_safe_search, {
                  safeSearch: _ctx.filters.safesearch
                }, null, 8, ["safeSearch"])
              ]),
              _: 1
            }),
            _createVNode(_component_content_menu_item, null, {
              "list-item": _withCtx(() => [
                _createVNode(_component_search_menu_dimensions, {
                  dimensions: _ctx.filters.dimensions
                }, null, 8, ["dimensions"])
              ]),
              _: 1
            }),
            (_ctx.filters.MediaTypesHasDuration())
              ? (_openBlock(), _createBlock(_component_content_menu_item, { key: 1 }, {
                  "list-item": _withCtx(() => [
                    _createVNode(_component_search_menu_duration, {
                      duration: _ctx.filters.duration
                    }, null, 8, ["duration"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}