<template>
  <path
    d="M8.500 7.000 A3.500 3.500 0 1 0 15.500 7.000 A3.500 3.500 0 1 0 8.500 7.000 Z"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M12,.5a6.856,6.856,0,0,1,6.855,6.856c0,3.215-4.942,11.185-6.434,13.517a.5.5,0,0,1-.842,0c-1.492-2.332-6.434-10.3-6.434-13.517A6.855,6.855,0,0,1,12,.5Z"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M17,19.7c3.848.324,6.5,1.009,6.5,1.8,0,1.105-5.148,2-11.5,2S.5,22.605.5,21.5c0-.79,2.635-1.473,6.458-1.8"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
