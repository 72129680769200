
import { Vue, Options } from 'vue-class-component';
import { Image } from '@/interfaces/Media';
import userCollections from '@/services/UserCollectionService';
import Masonry from 'masonry-layout';
import { collection } from '@/interfaces/collection';
import Upload from '@/components/global/Upload/Upload.vue';
import BaseDialog from '@/components/global/UI/BaseDialog.vue';
import BaseButton from '@/components/global/UI/BaseButton.vue';
import ColorButton from '@/components/global/UI/ColorButton.vue';
import BaseInput from '@/components/global/UI/BaseInput.vue';

@Options({
    components: {
        Upload,
        BaseDialog,
        BaseButton,
        ColorButton,
        BaseInput
    },
    props: {
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['close'],
})
export default class CollectionEditModal extends Vue {
    image!: Image;
    masonry!: Masonry;
    enabled!: boolean;
    isMobile!: boolean;
    title = '';
    file!: Blob;
    errMessage = '';
    collectionId = 0;

    mounted(): void {
        this.isMobile = screen.width < 768;
        this.collectionId = Number(this.$route.params.id);

        userCollections.get(Number(this.$route.params.id)).then(res => {
            const userCollection = res.data as collection;
            this.title = userCollection.name;
        });
    }

    deleteCollection(): void {
        const elementContent = document.getElementById('archive-modal-content');
        if(elementContent) {
            elementContent.style.display = 'none';
        }
        const elementConfirm = document.getElementById('confirm-dialogue');
        if(elementConfirm) {
            elementConfirm.style.display = 'block';
        }
    }

    cancelDelete(): void {
        const elementConfirm = document.getElementById('confirm-dialogue');
        if(elementConfirm) {
            elementConfirm.style.display = 'none';
        }
        const elementContent = document.getElementById('archive-modal-content');
        if(elementContent) {
            elementContent.style.display = 'block';
        }
        this.errMessage = '';
    }

    confirmDelete(): void {
        userCollections.delete(Number(this.$route.params.id), Number(this.image.id)).then((res) => {
            if (res.status === 200) {
                this.$emit('close');
            } else {
                this.errMessage = 'Unable to delete collection.';
            }
        });

        //this.$router.push('/collections');
    }

    upload(): void {
        const element = document.getElementById('upload-form');
        if(element) {
            element.style.display = 'block';
        }

        document.querySelector('.overlay-container')?.classList.add('overlay-active');
        document.querySelectorAll('.overlay-image').forEach(elem => {
            elem.classList.add('overlay-opacity-active');
        });
        document.querySelector('.overlay-button')?.classList.add('overlay-opacity-active');
    }


    onUpdate(): void {
        userCollections.update(Number(this.$route.params.id), this.title).then((res) => {
            if (res.status === 200) {
                this.$emit('close');
                this.$forceUpdate();
            } else {
                this.errMessage = 'Unable to update collection name.';
            }
        });
    }

    closeMe(): void {
        this.$emit('close');
    }
}
