import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["activeroute"]
const _hoisted_2 = { class: "topAndContent" }
const _hoisted_3 = {
  key: 0,
  ref: "mobileOverlay",
  class: "mobile-menu-content-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_auth_component = _resolveComponent("auth-component")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mainView",
    ref: "contentContainer",
    activeroute: _ctx.$route.name
  }, [
    _createVNode(_component_side_bar, { class: "sidebar" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_top_bar, { onToggleBackgroundOverlay: _ctx.toggleBackgroundOverlay }, null, 8, ["onToggleBackgroundOverlay"]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.displayMenuOverlay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, null, 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view)
    ]),
    (!_ctx.store.getters.loggedIn)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#modals"
        }, [
          _createVNode(_component_auth_component, { logoPath: "/assets/icon/Jumpstory-Logo.jpg" })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}