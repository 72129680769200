<template>
  <path
    d="M5.56521739,16 L0.695652174,16 C0.311454087,16 0,15.6885459 0,15.3043478 L0,10.4347826"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M5.56521739,0 L0.695652174,0 C0.311454087,0 0,0.311454087 0,0.695652174 L0,5.56521739"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M10.4347826,16 L15.3043478,16 C15.6885459,16 16,15.6885459 16,15.3043478 L16,10.4347826"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M10.4347826,0 L15.3043478,0 C15.6885459,0 16,0.311454087 16,0.695652174 L16,5.56521739"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="2.7826087"
    y1="6.60869565"
    x2="2.7826087"
    y2="9.39130435"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="4.52173913"
    y1="5.91304348"
    x2="4.52173913"
    y2="10.0869565"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="6.26086957"
    y1="5.2173913"
    x2="6.26086957"
    y2="10.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="13.2173913"
    y1="6.60869565"
    x2="13.2173913"
    y2="9.39130435"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="11.4782609"
    y1="5.91304348"
    x2="11.4782609"
    y2="10.0869565"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="9.73913043"
    y1="5.2173913"
    x2="9.73913043"
    y2="10.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="8"
    y1="3.13043478"
    x2="8"
    y2="12.8695652"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
