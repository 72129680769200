
import { Vue, Options } from 'vue-class-component';
import { Image } from '@/interfaces/Media';
import 'vue-next-select/dist/index.min.css';
import userCollection from '@/services/UserCollectionService';
import { collection } from '@/interfaces/collection';
import BaseDialog from '@/components/global/UI/BaseDialog.vue';
import ColorButton from '@/components/global/UI/ColorButton.vue';

@Options({
    props: {
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['close'],
    components: {
        BaseDialog,
        ColorButton
    }
})
export default class CollectionMoveFileModal extends Vue {
    image!: Image;
    enabled!: boolean;
    isMobile!: boolean;
    file!: null;
    value!: number;
    options!: Array<collection>;
    errMessage = '';
    showError = false;

    mounted(): void {
        this.isMobile = screen.width < 768;

        this.value = Number(this.$route.params.id);

        userCollection.view().then(res => {
            this.options = res.data;
        });
    }

    onUpdate(): void {
        if (this.value == null) {
            this.errMessage = 'Please select a collection you want to move the file to.';
            this.showError = true;
        }

        // ToDo: use move to collection endpoint to move file
    }

    closeMe(): void {
        this.$emit('close');
    }
}
