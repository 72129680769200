<template>
  <path
    d="M10.7818327,14.6046216 L11.4772908,14.6046216 C12.822307,14.6046216 13.9113944,13.8625677 13.9113944,12.5182471 L13.9113944,11.0091028"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M13.2159363,5.56366465 C14.5714632,5.56270567 15.7303328,6.53891001 15.9596445,7.87490039 C16.1889563,9.21089076 15.42194,10.5176576 14.1436775,10.9687663 C13.9308573,11.044179 13.6946722,11.0115821 13.510235,10.8813423 C13.3257977,10.7511025 13.2160657,10.539431 13.2159363,10.3136447 L13.2159363,5.56366465 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M2.78406373,5.56366465 C1.42853679,5.56270567 0.269667235,6.53891001 0.0403554537,7.87490039 C-0.188956327,9.21089076 0.578059971,10.5176576 1.85632253,10.9687663 C2.06914271,11.044179 2.30532777,11.0115821 2.48976502,10.8813423 C2.67420227,10.7511025 2.78393435,10.539431 2.78406373,10.3136447 L2.78406373,5.56366465 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M13.2159363,6.60685261 L13.2159363,5.21593627 C13.2159363,2.33525421 10.8806821,7.10542736e-15 8,7.10542736e-15 C5.11931794,7.10542736e-15 2.78406373,2.33525421 2.78406373,5.21593627 L2.78406373,6.60685261"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M7.65227092,13.9091634 L10.4341036,13.9091634 C10.6261491,13.9091634 10.7818327,14.064847 10.7818327,14.2568925 L10.7818327,14.9523507 C10.7818327,15.1443961 10.6261491,15.3000797 10.4341036,15.3000797 L7.65227092,15.3000797 C7.26817997,15.3000797 6.95681275,14.9887125 6.95681275,14.6046216 L6.95681275,14.6046216 C6.95681275,14.2205306 7.26817997,13.9091634 7.65227092,13.9091634 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
