<template>
  <path
    d="M6.09788235,1.88235294 C6.09788235,2.92194776 6.94064047,3.76470588 7.98023529,3.76470588 C9.01983012,3.76470588 9.86258824,2.92194776 9.86258824,1.88235294 C9.86258824,0.842758118 9.01983012,0 7.98023529,0 C6.94064047,0 6.09788235,0.842758118 6.09788235,1.88235294 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="9.86070588"
    y1="1.88235294"
    x2="16"
    y2="1.88235294"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="4.4408921e-16"
    y1="1.88235294"
    x2="6.09411765"
    y2="1.88235294"
    id="Path"
    stroke="#000000"
  ></line>
  <path
    d="M2.33317647,8 C2.33317647,9.03959482 3.17593459,9.88235294 4.21552941,9.88235294 C5.25512423,9.88235294 6.09788235,9.03959482 6.09788235,8 C6.09788235,6.96040518 5.25512423,6.11764706 4.21552941,6.11764706 C3.17593459,6.11764706 2.33317647,6.96040518 2.33317647,8 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <line x1="6.09411765" y1="8" x2="16" y2="8" id="Path" stroke="#000000"></line>
  <line
    x1="4.4408921e-16"
    y1="8"
    x2="2.32941176"
    y2="8"
    id="Path"
    stroke="#000000"
  ></line>
  <path
    d="M4.21552941,14.1176471 C4.21552941,15.1572419 5.05828753,16 6.09788235,16 C7.13747718,16 7.98023529,15.1572419 7.98023529,14.1176471 C7.98023529,13.0780522 7.13747718,12.2352941 6.09788235,12.2352941 C5.05828753,12.2352941 4.21552941,13.0780522 4.21552941,14.1176471 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="7.97647059"
    y1="14.1176471"
    x2="16"
    y2="14.1176471"
    id="Path"
    stroke="#000000"
  ></line>
  <line
    x1="1.33226763e-15"
    y1="14.1176471"
    x2="4.21552941"
    y2="14.1176471"
    id="Path"
    stroke="#000000"
  ></line>
</template>
;
