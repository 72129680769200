
import { Options, Vue } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import Tooltip from '../UI/Tooltip.vue';
import { tooltip } from './ThumbModal.vue';

@Options({
    props: {
        tooltip: {
            type: Object,
            required: true,
        },
        editType: {
            type: String,
            required: true
        }
    },
    components: {
        BaseIcon,
        Tooltip,
    },
    emits: ['preventToggle'],
})
export default class RemoveBackgroundToggler extends Vue {
  tooltip!: tooltip;
  editType!: string;

  mounted(): void {
      const iconLeft: HTMLDivElement = this.$refs.iconLeft as HTMLDivElement;
      const iconRight: HTMLDivElement = this.$refs.iconRight as HTMLDivElement;

      iconLeft.classList.remove('icon-selected');
      iconRight.classList.remove('icon-selected');

      if (this.editType === 'BACKGROUND_REMOVED') {
          iconLeft.classList.add('icon-selected');
      }

      if (this.editType === 'ORIGINAL') {
          iconRight.classList.add('icon-selected');
      }
  }

  preventClick(state: string): void {
      let preventState = false;
      if (state === this.editType) {
          preventState = true;
      }
      this.$emit('preventToggle', preventState);
  }
}
