
import { Vue, Options } from 'vue-class-component';
import Accordion from '@/components/global/Accordion/Accordion.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import { Watch } from 'vue-property-decorator';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Filters, Orientations } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        Accordion,
        BaseIcon,
    },
    props: {
        orientations: {
            type: Object,
            required: true,
        },
    },
})
export default class SearchMenuOrientation extends Vue {
  // @ts-ignore
  store: Store<RootState>;
  orientations!: Orientations;
  filters!: Filters;
  open = false;

  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters && this.filters.close_all) {
          this.open = false;
      }
  }


  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
  }

  toggleAccordionIcon(): void {
      this.open = !this.open;
  }

  allEnabled(): boolean {
      return (
          this.orientations.vertical.enabled &&
          this.orientations.horizontal.enabled &&
          this.orientations.square.enabled
      );
  }

  setAllEnabled(): void {
      this.orientations.vertical.enabled = true;
      this.orientations.horizontal.enabled = true;
      this.orientations.square.enabled = true;
  }
}
