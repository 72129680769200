
import { Options, Vue } from 'vue-class-component';
import BaseView from '@/views/BaseView/BaseView.vue';
import AccountMenu from '@/components/Account/AccountMenu.vue';
import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import router from '@/router';
import ChangePassword, {
    setDefaultAlertOptions,
} from '@/components/Account/ChangePassword.vue';
import userService, { userRes } from '@/services/userService';
import Swal from 'sweetalert2';
import { AxiosResponse } from 'axios';
import { logoutResPayload } from '@/store/modules/auth';

@Options({
    components: {
        BaseView,
        AccountMenu,
        ChangePassword,
    },
})
export default class Account extends Vue {
  store!: Store<RootState>;
  user: userRes = {
      id: '',
      is_organization_owner: false,
      checkout_status: 'COMPLETED',
      first_name: '',
      last_name: '',
      email: '',
  };

  mounted(): void {
      this.store = useStore();
      this.getUser();
  }

  getUser(): void {
      userService.get(this.store.getters.ownId).then(
          (user) => {
              this.user = user.data;
          },
          (err) => {
              console.log('User err');
              console.log(err);
          }
      );
  }

  logoutUser(): Promise<void> {
      const logoutAlert = Swal.mixin(setDefaultAlertOptions());

      return this.store.dispatch('logout').then(
          (authResult: AxiosResponse<logoutResPayload>) => {
              if (authResult.status === 200) {
                  router.push('/');
                  return;
              }
          },
          () => {
              logoutAlert.fire({
                  title: 'An error occurred',
                  text:
            'There was an error logging out, our development team has been notified.',
              });
              return;
          }
      );
  }
}
