<template>
  <path
          d="M6.500 9.500 A2.000 2.000 0 1 0 10.500 9.500 A2.000 2.000 0 1 0 6.500 9.500 Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
  <path
          d="M13.924,12.178a.5.5,0,0,0-.848,0L10.437,16.4,9.362,14.678a.5.5,0,0,0-.848,0L5.5,19.5h13Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
  <path
          d="M21.207,4.5a1,1,0,0,1,.293.707V22.5a1,1,0,0,1-1,1H3.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H16.793A1,1,0,0,1,17.5.793Z"
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
  ></path>
</template>
;