
import { Vue, Options } from 'vue-class-component';

    @Options({
        props: {
            text: {
                type: String
            },
            type_icon: {
                type: Boolean
            }
        }
    })

export default class Tooltip extends Vue {}
