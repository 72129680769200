import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce336a0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrap",
  role: "group",
  "aria-labelledby": "multi-lbl"
}
const _hoisted_2 = ["min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rangeB) = $event)),
          id: "b",
          type: "range",
          min: _ctx.min,
          max: _ctx.max,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rangeBChanged())),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rangeBChanged()))
        }, null, 40, _hoisted_2), [
          [_vModelText, _ctx.rangeB]
        ])
      ]))
    : _createCommentVNode("", true)
}