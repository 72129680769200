import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vueper_slide = _resolveComponent("vueper-slide")!
  const _component_vueper_slides = _resolveComponent("vueper-slides")!

  return (_openBlock(), _createBlock(_component_vueper_slides, {
    "dragging-distance": 200,
    gap: 3,
    "slide-ratio": 1 / 4,
    "visible-slides": 4,
    class: "no-shadow",
    "fixed-height": "110px",
    bullets: false,
    "slide-multiple": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
        return (_openBlock(), _createBlock(_component_vueper_slide, {
          key: i,
          image: image.thumbnail_url
        }, null, 8, ["image"]))
      }), 128))
    ]),
    _: 1
  }))
}