<template>
  <path
    d="M14.9827228,2.7826087 C15.132934,2.91303842 15.217391,3.08995143 15.217391,3.27443478 L15.217391,15.3043478 C15.217391,15.6885459 14.858809,16 14.4164757,16 L0.800915316,16 C0.358582001,16 6.75015599e-14,15.6885459 6.75015599e-14,15.3043478 L6.75015599e-14,0.695652174 C6.75015599e-14,0.311454087 0.358582001,0 0.800915316,0 L11.4474826,0 C11.6598812,0 11.8635639,0.0733569567 12.0137297,0.203826087 L14.9827228,2.7826087 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M6.20765217,5.30504348 C5.99218715,5.18522547 5.72939868,5.18839988 5.51689083,5.31338767 C5.30438299,5.43837546 5.17391304,5.66650437 5.17391304,5.91304348 L5.17391304,11.1304348 C5.17391304,11.3769739 5.30438299,11.6051028 5.51689083,11.7300906 C5.72939868,11.8550784 5.99218715,11.8582528 6.20765217,11.7384348 L10.7293913,8.95582609 C10.9501264,8.83310192 11.0870081,8.60038345 11.0870081,8.34782609 C11.0870081,8.09526873 10.9501264,7.86255025 10.7293913,7.73982609 L6.20765217,5.30504348 Z"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
