
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Media_type } from '@/store/modules/filters/media_filters';
@Options({
    props: {
        totalHits: {
            type: Number,
            required: false,
        }
    },
})

export default class SearchTotals extends Vue {
    // @ts-ignore
    store: Store<RootState>;
    totalHits!: number;
    keywords!: string;
    // @ts-ignore
    media_types: Array<Media_type>;
    mediaTypeTotals = '';

    @Watch('$store.getters.filters', { immediate: true, deep: true })
    onFiltersChange(): void {
        if (this.store) {
            this.keywords = this.store.getters.filters.search_string;
            this.media_types = this.store.getters.filters.media_types;

            const mediaTypes: Array<Media_type> = [];
            this.media_types.forEach((val) => {
                if (val.enabled) {
                    mediaTypes.push(val);
                }
            });
            const typesCount = mediaTypes.length;
            this.mediaTypeTotals = '';
            mediaTypes.forEach((val, i) => {
                const mediaTypeName = val.display.toLowerCase();
                if (i === 0) {
                    this.mediaTypeTotals += mediaTypeName;
                } else if (i === typesCount-1) {
                    this.mediaTypeTotals += ' and ' + mediaTypeName;
                } else {
                    this.mediaTypeTotals += ', ' + mediaTypeName;
                }
            });
        }
    }

    mounted(): void {
        this.store = useStore();
    }

}
