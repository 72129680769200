<template>
  <path
    d="M0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <line
    x1="3.63636364"
    y1="8"
    x2="13.0909091"
    y2="8"
    id="Path"
    stroke="#000000"
  ></line>
  <polyline
    id="Path"
    stroke="#000000"
    points="9.81818182 4.72727273 13.0909091 8 9.81818182 11.2727273"
  ></polyline>
</template>
;
