
import { Vue, Options } from 'vue-class-component';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';

@Options({
    props: {
        filterKey: {
            type: String,
            required: true,
        },
        filterValue: {
            type: String,
            required: true,
        },
        filterEnabled: {
            type: Boolean,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        amount: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    components: {
        BaseIcon
    }
})
export default class SearchMenuMediaTypeItem extends Vue {
    filterKey!: string;
    filterValue!: string;
    filterEnabled!: boolean;
    amount!: number;
    iconName = '';

    mounted(): void {
        this.iconName = this.filterValue.toLowerCase();
    }

    addFilter(key: string, value: string): void {
        // @ts-ignore
        this.$parent.$parent.$parent.$emit('search-filter', key, value, 'add');
    }
}
