
import { Options, Vue } from 'vue-class-component';
import { Image } from '@/interfaces/Media';
import ThumbModal from '../ThumbModal/ThumbModal.vue';
import search from '@/services/searchService';
import userCollection from '@/services/UserCollectionService';
import { DownloadableFile } from '@/interfaces/DownloadableFile';
import VideoPlayer from '@/components/global/Video/VideoPlayer.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

@Options({
    props: {
        images: {
            type: Array,
            required: true,
        },
        media: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        searchId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        mediaId: {
            type: Number,
            required: true
        },
        isPublic: {
            type: Boolean,
            required: false,
        },
        isCollection: {
            type: Boolean,
            required: false,
            default: false
        },
        triggerNextLoad: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    components: {
        ThumbModal,
        VideoPlayer,
        FontAwesomeIcon
    },
    emits: ['remove']
})

export default class Thumbnail extends Vue {
  store!: Store<RootState>;
  images!: Array<Image>;
  media!: Image;
  index!: number;
  searchId!: number;
  pageId!: number;
  mediaId!: number;
  isPublic!: boolean;
  thumbModalEnabled = false;
  isCollection!: boolean;
  mediaFileDimensions = '';
  update = false;

  mounted(): void {
      if (this.media) {
          this.media.downloadable_files.forEach((file) => {
              if (file.size === 'FULL') {
                  this.mediaFileDimensions = file.width + ' x ' + file.height;
              }
          });
      }
  }

  toggleThumbModal(): void {
      window.scrollTo(0, 0);
      this.thumbModalEnabled = !this.thumbModalEnabled;
  }

  moveFile(): void {
      // @ts-ignore
      this.$parent.$emit('move', this.images[this.index].id);
  }

  removeFile(): void {
      // @ts-ignore
      this.$parent.$emit('remove', this.images[this.index].id);
  }

  toggleFavorite(): void {
      userCollection.favorites().then((res) => {
          if (res.status === 200) {
              if (res.data.id) {
                  if (!this.images[this.index].is_favorite) {
                      userCollection.add(res.data.id, this.mediaId).then((res) => {
                          if (res.status === 200) {
                              this.images[this.index].is_favorite = true;
                          }
                      });
                  } else {
                      userCollection.delete(res.data.id, this.mediaId).then((res) => {
                          if (res.status === 200) {
                              this.images[this.index].is_favorite = false;
                          }
                      });
                  }
                  this.update = true;
              }
          }
      });
  }

  downloadMedia(): void {
      const file = this.getFullSize(this.media);
      if (file) {
          search.download(this.searchId, this.pageId, this.mediaId, this.media.key, 'ORIGINAL', file.id);
      }
  }

  getFullSize(image: Image): DownloadableFile {
      let downloadFile: DownloadableFile;
      image.downloadable_files.forEach((file) => {
          if (file.size === 'FULL') {
              downloadFile = file;
          }
      });
      // @ts-ignore
      return downloadFile;
  }

  openModal(): void {
      this.update = true;
  }
}

