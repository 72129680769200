
import { Vue, Options } from 'vue-class-component';
import BaseSpinner from '@/components/global/UI/BaseSpinner.vue';
import SignupForm from './SignupForm.vue';
import LoginForm from './LoginForm.vue';
import { tokenResPayload } from '@/store/modules/auth/index';
import { AxiosResponse } from 'axios';
import ResetPasswordForm from './ResetPasswordForm.vue';
import ResetPasswordCallbackForm from './ResetPasswordCallbackForm.vue';

@Options({
    props: {
        logoPath: {
            type: String,
            required: false,
        },
        plan_id: {
            type: String,
            required: false,
        },
    },
    components: {
        BaseSpinner,
        SignupForm,
        LoginForm,
        ResetPasswordForm,
        ResetPasswordCallbackForm,
    },
})
export default class Auth extends Vue {
  props = ['asss'];
  isLoading = false;
  authResult!: AxiosResponse<tokenResPayload>;

  mode = 'login';
  logoPath = '/assets/icon/Jumpstory-Logo.jpg';

  mounted(): void {
      if (this.$route?.query?.token) {
          this.mode = 'reset_pw_callback';
      }
  }

  switchAuthMode(mode: string): void {
      this.mode = mode;
  }
}
