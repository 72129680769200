import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02c8b91e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-control" }
const _hoisted_2 = { class: "form-control" }
const _hoisted_3 = { class: "form-control" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "form-control" }
const _hoisted_6 = { class: "form-control" }
const _hoisted_7 = { class: "form-control" }
const _hoisted_8 = { class: "form-control" }
const _hoisted_9 = { class: "form-control" }
const _hoisted_10 = { class: "side-by-side-input-grid" }
const _hoisted_11 = { class: "form-control side-by-side-input-left" }
const _hoisted_12 = { class: "form-control side-by-side-input-right" }
const _hoisted_13 = { class: "side-by-side-input-grid" }
const _hoisted_14 = { class: "form-control side-by-side-input-left" }
const _hoisted_15 = { class: "form-control side-by-side-input-right" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.page === 1)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Email",
              type: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.email,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Password",
              type: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.password,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              type: "password",
              placeholder: "Password verification",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordCheck) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.passwordCheck,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goToPageTwo()), ["prevent"])),
              class: "button button-active"
            }, " Sign up ")
          ])
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.page === 2)
      ? (_openBlock(), _createElementBlock("form", {
          key: 1,
          onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "First name",
              type: "text",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.firstName) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.firstName,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Last name",
              type: "text",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.lastName) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.lastName,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Company (Optional)",
              type: "text",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.company) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.company,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Address Line 1",
              type: "text",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addressLineOne) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.addressLineOne,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              class: "form-input",
              placeholder: "Address Line 2 (Optional)",
              type: "text",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addressLineTwo) = $event))
            }, null, 512), [
              [
                _vModelText,
                _ctx.addressLineTwo,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                class: "form-input",
                placeholder: "City",
                type: "text",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.city) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _ctx.city,
                  void 0,
                  { trim: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("input", {
                class: "form-input",
                placeholder: "Zip",
                type: "text",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.zip) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _ctx.zip,
                  void 0,
                  { trim: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                class: "form-input",
                placeholder: "State",
                type: "text",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _ctx.state,
                  void 0,
                  { trim: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("select", {
                class: "form-input form-input-select",
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.country) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, ([code, name]) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: code,
                    value: code
                  }, _toDisplayString(name), 9, _hoisted_16))
                }), 128))
              ], 512), [
                [
                  _vModelSelect,
                  _ctx.country,
                  void 0,
                  { trim: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.submitForm()), ["prevent"])),
              class: "button button-active"
            }, " Sign up ")
          ])
        ], 32))
      : _createCommentVNode("", true)
  ], 64))
}