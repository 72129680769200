
import { Options, Vue } from 'vue-class-component';
import MenuComponent from '../MenuComponent/MenuComponent.vue';

@Options({
    components: {
        MenuComponent,
    },
})
export default class SideBar extends Vue {}
