
import { Vue, Options } from 'vue-class-component';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    props: {
        toggleShow: {
            type: Boolean,
            required: false,
            default: false
        },
    },
})

export default class AccordionItem extends Vue {
  // @ts-ignore
  store: Store<RootState>;
  filters!: Filters;
  toggleShow!: boolean;
  open = false;

  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters && this.filters.close_all) {
          this.open = false;
      }
  }
  
  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
      if (this.toggleShow) {
          this.open = this.toggleShow;
      }
  }
  
  toggleOpen(): void {
      this.open = !this.open;
  }
}
