
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import BaseView from './BaseView/BaseView.vue';
import { Store, useStore } from 'vuex';

import search, { searchRes } from '@/services/searchService';
import {Image, Media} from '@/interfaces/Media';
import ThumbCollection from '@/components/global/ThumbCollection/ThumbCollection.vue';
import { RootState } from '@/store/types';

import SearchMenu from '@/components/Search/SearchMenu.vue';
import SearchFiltersSelected from '@/components/Search/SearchFiltersSelected.vue';
import SearchTotals from '@/components/Search/SearchTotals.vue';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        BaseView,
        ThumbCollection,
        SearchMenu,
        SearchFiltersSelected,
        SearchTotals
    },
})
export default class Search extends Vue {
  store!: Store<RootState>;
  images!: Array<Image>;
  imagesLoaded = false;
  totalHits?: number = 0;
  appendKey = 0;
  componentKey = 0;
  currentPage = 1;
  isLoading = false;
  scrollHeight = 0;
  contentViewport = 0;
  disableViewportCheck = false;
  searchId!: number;
  pageId!: number;
  isFirstPage!: boolean;
  isLastPage!: boolean;

  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
      window.addEventListener('scroll', this.handleScroll, { passive: true });

      const ps = new PerfectScrollbar('#filter-overlay-menu');
      this.contentViewport = window.innerHeight;
      ps.update();

      if (this.filters.search_string) {
          this.initiateSearch();
      }
  }

  filters!: Filters;
  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters) {
          this.initiateSearch();
          const filterMenuMobile: HTMLDivElement = this.$refs.filterMenuMobile as HTMLDivElement;
          filterMenuMobile.style.display = this.filters.collapse ? 'block' : 'none';
      }
  }

  initiateSearch(): void {
      this.componentKey = this.updateComponentKey();
      this.isLoading = true;
      this.images = [];
      search.search(this.filters.formatForApi()).then((res) => {
          const searchResponse = res.data as searchRes;
          if (searchResponse?.page?.media) {
              const images = searchResponse.page.media;
              this.images = this.setTriggerElement(images);
              this.searchId = searchResponse.id;
              this.pageId = searchResponse.page.id;
              this.isFirstPage = searchResponse.page.is_first;
              this.isLastPage = searchResponse.page.is_last;
              this.imagesLoaded = true;
              this.totalHits = searchResponse.total_hits;
              this.appendKey = 1;
              this.isLoading = false;
          }
      });
  }

  unmounted(): void {
      window.removeEventListener('scroll', this.handleScroll);
  }

  async handleScroll(): Promise<void> {
      const elementTriggerNext = document.getElementById('triggerNextLoad');
      if (!this.disableViewportCheck && !this.isLastPage) {
          await this.isElementInViewport(elementTriggerNext);
      }
  }

  setTriggerElement(media: Array<Media>): Array<Media> {
      if (media[40]) {
          media[40].trigger_next = true;
      }
      return media;
  }

  async isElementInViewport(element: HTMLElement | null): Promise<void> {
      if (element) {
          const bounding = element.getBoundingClientRect();
          if (
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
              bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          ) {
              this.disableViewportCheck = true;
              element.removeAttribute('id');
              await this.getNextPage();
          }
      }
  }

  async getNextPage(): Promise<void> {
      if (!this.isLastPage) {
          this.isLoading = true;
          search.getNextPage(this.searchId, this.pageId).then((res) => {
              const searchResponse = res.data as searchRes;
              if (searchResponse?.page?.media) {
                  const images = searchResponse.page.media;
                  this.images = this.setTriggerElement(images);
                  this.disableViewportCheck = false;
                  this.searchId = searchResponse.id;
                  this.pageId = searchResponse.page.id;
                  this.isFirstPage = searchResponse.page.is_first;
                  this.isLastPage = searchResponse.page.is_last;
                  this.imagesLoaded = true;
                  this.appendKey += 1;
              }
          });
      }
  }

  closeFilterOverlay(): void {
      const filterMenuMobile: HTMLDivElement = this.$refs.filterMenuMobile as HTMLDivElement;
      filterMenuMobile.style.display = 'none';
      this.filters.collapse = false;
  }

  updateComponentKey(): number {
      return this.componentKey += 1;
  }
}
