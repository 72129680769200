
import {Vue} from 'vue-class-component';
import userService, {changePasswordReq} from '@/services/userService';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import router from '@/router';

export default class ChangePassword extends Vue {
  currentPassword = '';
  newPassword = '';
  passwordCheck = '';
  currentIsValid = true;
  passLengthIsValid = true;
  passMatchIsValid = true;

  submitForm(): void {
      // Resetting error message
      this.currentIsValid = true;
      this.passLengthIsValid = true;
      this.passMatchIsValid = true;

      if (this.newPassword == this.currentPassword) {
          this.currentIsValid = false;
          return;
      }
      if (this.newPassword.length < 6) {
          this.passLengthIsValid = false;
          return;
      }
      if (this.newPassword != this.passwordCheck) {
          this.passMatchIsValid = false;
          return;
      }
      const cpr: changePasswordReq = {
          old_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.passwordCheck
      };

      const cprAlert = Swal.mixin(setDefaultAlertOptions());

      userService.changePassword(2, cpr).then(
          () => {
              cprAlert.fire({
                  title: 'Password changed',
                  text: 'Your password has been successfully changed.',
                  didDestroy(): void {
                      router.push({ path: 'account' });
                      return;
                  }
              });
          },
          () => {
              cprAlert.fire({
                  title: 'Password has not been changed',
                  text: 'An error occurred while changing your password. Please try again.',
                  didDestroy(): void {
                      router.push({ path: 'account' });
                      return;
                  }
              });
          }
      );
  }
}

export function setDefaultAlertOptions(): SweetAlertOptions {
    const alertOptions: SweetAlertOptions = {
        confirmButtonText: 'Close',
        showCloseButton: true,
        allowEscapeKey: true,
        allowOutsideClick: true,
        customClass: {
            container: 'alert-container',
            confirmButton: 'form-button',
            denyButton: 'form-button',
            cancelButton: 'form-button',
        },
        backdrop: 'rgba(0, 0, 0, 0.75)',
        buttonsStyling: false,
    };

    return alertOptions;
}
