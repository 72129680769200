
import {Options, Vue} from 'vue-class-component';
import userCollections from '@/services/UserCollectionService';
import publicCollections from '@/services/collectionService';
import BaseAccordion, {itemProps} from '@/components/global/UI/BaseAccordion.vue';
import {collection} from '@/interfaces/collection';
import ContentMenuItem from '@/components/global/ContentMenu/ContentMenuItem.vue';
import ContentMenu from '@/components/global/ContentMenu/ContentMenu.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';

@Options({
    components: {
        BaseAccordion,
        ContentMenu,
        ContentMenuItem,
        BaseIcon
    },
})
export default class CollectionMenu extends Vue {
    myCollections: Array<itemProps> = [];
    curatedCollections: Array<itemProps> = [];

    mounted(): void {
        userCollections.view().then((res) => {
            const userCollectionData = res.data;

            userCollectionData.forEach((item) => {
                this.myCollections.push({
                    type: 'text',
                    text: item.name,
                    link: '/collections/' + item.id,
                    icon: 'folder',
                    iconTag: 'icon-folder',
                });
            });
        });

        publicCollections.view().then((res) => {
            const curatedCollectionData = res.data as Array<collection>;

            curatedCollectionData.forEach((item) => {
                this.curatedCollections.push({
                    type: 'text',
                    text: item.name,
                    link: '/collections/public/' + item.id,
                    icon: 'folder',
                    iconTag: 'icon-folder',
                });
            });
        });
    }
}
