
import { Vue } from 'vue-class-component';

export default class Checkout extends Vue {
  email = '';
  password = '';
  formIsValid = true;

  submitForm(): void {
      if (
          this.email === '' ||
      this.email.includes('@') ||
      this.password.length < 6
      ) {
          this.formIsValid = false;
          return;
      }
  }
}
