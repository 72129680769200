import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Home from '@/views/Home.vue';
import Search from '@/views/Search.vue';
import Checkout from '@/components/global/Checkout/Checkout.vue';
import Payment from '@/views/Payment.vue';
import Collection from '@/views/Collections.vue';
import CollectionView from '@/components/Collection/CollectionView.vue';
import NotFound from '@/components/NotFound/NotFound.vue';
import Account from '@/components/Account/Account.vue';
import store from '@/store';
import TextMatch from '@/components/TextMatch/TextMatch.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Search JumpStory'
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {
            title: 'Search JumpStory'
        }
    },
    {
        path: '/collections',
        name: 'Collections',
        component: Collection,
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/collections/:id',
        name: 'My Collections',
        component: CollectionView,
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/collections/public',
        name: 'PublicCollections',
        component: Collection,
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/collections/public/:id',
        name: 'Curated Collections',
        component: CollectionView,
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/textmatch',
        name: 'TextMatch',
        component: TextMatch,
        meta: {
            title: 'Text-Match'
        }
    },
    {
        path: '/userinfo',
        name: 'UserInfo',
        component: Collection,
        meta: {
            title: 'User'
        }
    },
    {
        path: '/payment/:site/:id',
        name: 'payment',
        component: Payment,
        meta: {
            title: 'Payment'
        }
    },
    {
        path: '/checkout',
        name: 'Check out',
        component: Checkout,
        meta: {
            title: 'Checkout'
        }
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
            title: 'My account'
        }
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        meta: {
            title: 'Search JumpStory'
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,

});

router.afterEach((to, from) => {
    if (to.name !== 'Search' && from.name === 'Search') {
        store.getters.filters.search_input = '';
    }
});

export default router;
