
import { Vue, Options } from 'vue-class-component';

import { RootState } from '@/store/types';
import { Store, useStore } from 'vuex';
import Swal from 'sweetalert2';

import { initPayload } from './AuthInterfaces';
import { validateEmail, validatePassword } from './LoginForm.vue';
import { setDefaultAlertOptions } from '@/components/Account/ChangePassword.vue';
import { AxiosResponse } from 'axios';
import CountryList from './CountryList';

@Options({
    props: {
        plan_id: {
            type: String,
            required: false,
            default: '14_days_trial_25_month',
        },
    },
    components: {},
})
export default class SignupForm extends Vue {
  page = 1;
  email = '';
  password = '';
  passwordCheck = '';
  firstName = '';
  lastName = '';
  company = '';
  addressLineOne = '';
  addressLineTwo = '';
  city = '';
  zip = '';
  state = '';
  country = '';
  countries = CountryList as Map<string, string>;

  store!: Store<RootState>;
  plan_id = '';

  mounted(): void {
      this.store = useStore();
  }

  goToPageTwo(): void {
      const signupAlert = Swal.mixin(setDefaultAlertOptions());

      if (!validateEmail(this.email)) {
          return;
      }

      if (!validatePassword(this.password)) {
          return;
      }

      if (this.password !== this.passwordCheck) {
          signupAlert.fire({
              title: 'Wrong input',
              text: 'Your passwords needs to match.',
          });
          return;
      }
      this.page = 2;
  }

  submitForm(): void {
      const signupAlert = Swal.mixin(setDefaultAlertOptions());

      if (this.firstName.length < 2 || this.lastName.length < 2) {
          signupAlert.fire({
              title: 'Wrong input',
              text: 'Your first and last name needs to be at least two characters long.',
          });
          return;
      }
      this.$emit('update:loading', true);
      this.initUser();
      // The update:loading is in the initUser, sadly.
  }

  initUser(): void {
      const signupAlert = Swal.mixin(setDefaultAlertOptions());
      const payload: initPayload = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordCheck,
          first_name: this.firstName,
          last_name: this.lastName,
          address_line_one: this.addressLineOne,
          city: this.city,
          zip: this.zip,
          state: this.state,
          country: this.country,
          company: this.company,
          address_line_two: this.addressLineTwo,
          chargebee_plan_id: '14_days_trial_25_month',
      };
      this.store.dispatch('init', payload).then((authResult: AxiosResponse) => {
          this.$emit('update:loading', false);
          if (authResult.status === 200) {

              this.$router.push(
                  '/payment/' +
                this.extractHostedPageSite(authResult.data['hosted_page']) +
                '/' +
                this.extractHostedPageCode(authResult.data['hosted_page'])
              );
              return;
          }
          signupAlert.fire({
              title: 'An error occurred',
              text: 'There was an error creating you in our system. Our development team has been notified.',
          });
      });
  }

  extractHostedPageSite(input: string): string {
      return input.substring(
          input.indexOf('https://') + 8,
          input.indexOf('/pages/v3/')
      );
  }

  extractHostedPageCode(input: string): string {
      return input.substring(
          input.indexOf('/pages/v3/') + 10,
          input.lastIndexOf('/')
      );
  }
}
