
import {Options, Vue} from 'vue-class-component';
import UploadService from '@/services/UploadService';

@Options({
    components: {
        collection: {
            type: Number,
            required: true
        }
    },
})

export default class Upload extends Vue {
  image!: File;
  collection = 0;
  mounted(): void {
      const dropZone: HTMLDivElement = this.$refs.dropZone as HTMLDivElement;
      const dropZoneInput: HTMLInputElement = this.$refs.dropZoneInput as HTMLInputElement;
      const dropZonePrompt: HTMLSpanElement = this.$refs.dropZonePrompt as HTMLSpanElement;
      const dropZoneThumb: HTMLDivElement = this.$refs.dropZoneThumb as HTMLDivElement;
      const uploadButtonDiv: HTMLDivElement = this.$refs.uploadButtonDiv as HTMLDivElement;

      dropZone.addEventListener('click', () => {
          dropZoneInput.click();
      });

      dropZoneInput.addEventListener('change', () => {
          if (dropZoneInput?.files?.length) {
              updateThumbnail(dropZone, dropZoneInput.files[0]);
              this.image = dropZoneInput.files[0];
              uploadButtonDiv.style.display = 'flex';
          }
      });

      dropZone.addEventListener('dragover', e => {
          e.preventDefault();
          dropZone.classList.add('drop-zone--over');
      });

      ['dragleave', 'dragend'].forEach(type => {
          dropZone.addEventListener(type, e => {
              e.preventDefault();
              dropZone.classList.remove('drop-zone--over');
          });
      });

      dropZone.addEventListener('drop', e => {
          e.preventDefault();
          if (e.dataTransfer?.files.length) {
              dropZoneInput.files = e.dataTransfer.files;
              updateThumbnail(dropZone, e.dataTransfer.files[0]);
              this.image = dropZoneInput.files[0];
              uploadButtonDiv.style.display = 'flex';
          }
          dropZone.classList.remove('drop-zone--over');
      });

      function updateThumbnail(dropZone: HTMLDivElement, file: File) {
      // First time remove the prompt.
          if (dropZonePrompt) {
              dropZonePrompt.remove();
              dropZoneThumb.style.display = 'flex';
          }
          dropZoneThumb.dataset.label = file.name;
          // Show thumbnail for image files
          if (file.type.startsWith('image/')) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  dropZoneThumb.style.backgroundImage = `url('${reader.result}')`;
              };
          } else {
              dropZoneThumb.style.backgroundImage = '';
          }
      }
  }

  upload(): void {
      UploadService.upload(this.image).then(
          (res) => {
              console.log('Uploaded');
              console.log(res);
          },
          (err) => {
              console.log(err);
          }
      );
  }
}
