<template>
  <polygon id="Path" stroke="#000000" points="0 0 20 0 20 20 0 20"></polygon>
  <line
    x1="20"
    y1="14.7826087"
    x2="0"
    y2="14.7826087"
    id="Path"
    stroke="#000000"
  ></line>
  <path
    d="M7.82608696,2.60869565 C6.76229242,2.61201237 5.8571593,3.38468698 5.68695652,4.43478261 C5.19847667,4.24688734 4.64498912,4.36916411 4.28112835,4.74535893 C3.91726759,5.12155376 3.8135029,5.67880861 4.01757169,6.16075583 C4.22164049,6.64270305 4.69402057,6.95600345 5.2173913,6.95652174 L7.82608696,6.95652174 C9.02670598,6.95652174 10,5.98322772 10,4.7826087 C10,3.58198967 9.02670598,2.60869565 7.82608696,2.60869565 L7.82608696,2.60869565 Z"
    id="Path"
    stroke="#000000"
  ></path>
  <path
    d="M2.60869565,14.7826087 C2.60869565,14.7826087 4.34782609,10.4347826 6.52173913,10.4347826 C8.69565217,10.4347826 10.4347826,13.0434783 10.4347826,13.0434783 L13.0565217,8.54956522 C13.3271533,8.08765691 13.8291239,7.81117567 14.3641672,7.82932504 C14.8992105,7.8474744 15.3812916,8.15733596 15.62,8.63652174 L18.6956522,14.7826087"
    id="Path"
    stroke="#000000"
  ></path>
</template>
;
