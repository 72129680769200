
import { Vue, Options } from 'vue-class-component';
import { ColorPicker } from 'vue-color-kit';
import '@/styles/vue-color-kit.css';
import { convertHEXtoHSL } from './searchFilterFunctions';
import Accordion from '@/components/global/Accordion/Accordion.vue';
import { Watch } from 'vue-property-decorator';
import { Store, useStore } from 'vuex';
import { RootState } from '@/store/types';
import { Color as colorInterface, Filters } from '@/store/modules/filters/media_filters';
interface colorPickerColor {
  hex: string;
}

@Options({
    components: {
        ColorPicker,
        Accordion,
    },
    props: {
        colorObject: {
            type: Object,
            required: true,
        },
    },
})
export default class SearchMenuColorFilter extends Vue {
  // @ts-ignore
  store: Store<RootState>;
  filters!: Filters;
  open = false;
  id = 'color';
  color = '#FF0040';
  colorObject!: colorInterface;

  @Watch('$store.getters.filters', { immediate: true, deep: true })
  onFiltersChange(): void {
      if (this.filters && this.filters.close_all) {
          this.open = false;
      }
  }


  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
  }

  changeColor(color: colorPickerColor): void {
      const hsl = convertHEXtoHSL(color.hex);
      if (hsl) {
          this.colorObject.value = {
              hue: hsl.h,
              saturation: hsl.s,
              luminosity: hsl.l,
          };
          this.colorObject.enabled = true;
      }
  }

  toggleAccordionIcon(): void {
      this.open = !this.open;
      //this.colorObject.enabled = true;
  }
}
