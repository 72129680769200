
import { Vue, Options } from 'vue-class-component';

@Options({
    props: {
        isActive: {
            type: Boolean,
        },
    },
})
export default class ContentMenuItem extends Vue {
  isActive?: boolean;
  mounted(): void {
      const listItem = this.$refs.listItem as HTMLDivElement;
      if (this.isActive) {
          listItem.style.backgroundColor = '#f1f1f5';
      } else {
          listItem.style.backgroundColor = '#f7f8fb';
      }
  }

  updated(): void {
      const listItem = this.$refs.listItem as HTMLDivElement;
      if (this.isActive) {
          listItem.style.backgroundColor = '#f1f1f5';
      } else {
          listItem.style.backgroundColor = '#f7f8fb';
      }
  }

  activateMe(): void {
      this.$emit('activate');
  }
}
