import api, { apiError } from './api';
import { AxiosResponse } from 'axios';
import { Media } from '@/interfaces/Media';
import { QuickSearch } from '@/interfaces/QuickSearch';
import { DownloadableFile } from '@/interfaces/DownloadableFile';

interface searchInterface {
    search(query: searchQuery): Promise<AxiosResponse<searchRes | apiError>>;

    autoComplete(search_for: string): Promise<AxiosResponse<Array<string> | apiError>>;

    getPreviousPage(searchID: number, page: number): Promise<AxiosResponse<searchRes | apiError>>;

    getNextPage(searchID: number, page: number): Promise<AxiosResponse<searchRes | apiError>>;

    view(searchID: number, page: number, media: number, key: string, editType: string): Promise<AxiosResponse<Media | apiError>>;

    download(searchID: number, page: number, media: number, key: string, editType: string, file: number): Promise<AxiosResponse<string | apiError>>;

    quickSearch(): Promise<AxiosResponse<Array<QuickSearch> | apiError>>;
}

export interface searchQuery {
    search_for?: string;
    hits_per_page?: number;
    filters?: filters;
}

export interface filters {
    media_types?: Array<string>;
    media_orientations?: Array<string>;
    color?: {
        hue: number;
        saturation: number;
        luminosity: number;
    };
    width?: {
        min: number;
        max: number;
    };
    height?: {
        min: number;
        max: number;
    };
    duration?: {
        min: number;
        max: number;
    };
    people?: {
        include: boolean;
        min: number;
        max: number;
    };
    content_moderation?: boolean;
}

export interface searchRes {
    id: number;
    total_hits?: number;
    page?: {
        id: number;
        is_first: boolean;
        is_last: boolean;
        hits: number;
        media?: Array<Media>;
    }
}

export interface viewRes {
    id: string;
    type: string;
    key: string;
    thumbnail_url: string;
    preview_url: boolean;
    is_favorite: boolean;
    is_background_remove_disabled: boolean;
    downloadable_files: Array<DownloadableFile>;
}

export interface downloadRes {
    signed_url: string;
}

class searchService implements searchInterface {
    search(query: searchQuery): Promise<AxiosResponse<searchRes | apiError>> {
        return api.post('/search', query);
    }

    autoComplete(search_for: string): Promise<AxiosResponse<Array<string> | apiError>> {
        return api.post('/search/auto-complete', {search_for: search_for});
    }

    getPreviousPage(searchID: number, page: number): Promise<AxiosResponse<searchRes | apiError>> {
        return api.get('/search/' + searchID + '/page/' + page + '/previous-page');
    }

    getNextPage(searchID: number, page: number): Promise<AxiosResponse<searchRes | apiError>> {
        return api.get('/search/' + searchID + '/page/' + page + '/next-page');
    }

    view(searchID: number, page: number, media: number, key: string, editType: string): Promise<AxiosResponse<viewRes | apiError>> {
        return api.get('/search/' + searchID + '/page/' + page + '/media/' + media + '/' + key + '/' + editType);
    }

    download(searchID: number, page: number, media: number, key: string, editType: string, file: number): Promise<AxiosResponse<string | apiError>> {
        return api.get('/search/' + searchID + '/page/' + page + '/media/' + media + '/' + key + '/' + editType + '/file/' + file);
    }

    quickSearch(): Promise<AxiosResponse<Array<QuickSearch> | apiError>> {
        return api.get('/quick/searches');
    }
}

const search = new searchService();

export default search;
