<template>
  <path
    d="M0.500 0.500 L18.500 0.500 L18.500 18.500 L0.500 18.500 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M0.5 15.5L18.5 15.5"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M4.343,20.5l14.8,2.98a1,1,0,0,0,1.178-.783L23.48,7.012A1,1,0,0,0,22.7,5.834l-2.2-.442"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M3,15.5a7.089,7.089,0,0,1,.565-2.7c.4-.8,2.063-1.35,4.153-2.124.565-.209.472-1.684.222-1.96A4.167,4.167,0,0,1,6.855,5.485,2.725,2.725,0,0,1,9.5,2.5a2.725,2.725,0,0,1,2.645,2.985A4.167,4.167,0,0,1,11.06,8.717c-.25.276-.343,1.751.222,1.96,2.09.774,3.754,1.327,4.153,2.124A7.089,7.089,0,0,1,16,15.5Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
