<template>
  <path
    d="M1.000 1.000 L19.000 1.000 L19.000 19.000 L1.000 19.000 Z"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M1 15L19 15"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M7.71,21,18,22.964a2,2,0,0,0,2.339-1.589L22.964,7.623a2,2,0,0,0-1.589-2.34L21,5.212"
    fill="none"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
