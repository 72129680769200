
import { Options, Vue } from 'vue-class-component';
import BaseView from './BaseView/BaseView.vue';
import CollectionMenu from '@/components/Collection/CollectionMenu.vue';
import CollectionIndex from '@/components/Collection/CollectionIndex.vue';

@Options({
    components: {
        BaseView,
        CollectionMenu,
        CollectionIndex,
    },
})
export default class Home extends Vue {
}
