import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DokaImageEditor = _resolveComponent("DokaImageEditor")!
  const _component_doka_dialog = _resolveComponent("doka-dialog")!

  return (_ctx.enabled)
    ? (_openBlock(), _createBlock(_component_doka_dialog, {
        key: 0,
        isThumb: true,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DokaImageEditor, _mergeProps(_ctx.editorDefaults, { src: _ctx.src }), null, 16, ["src"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}