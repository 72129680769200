<template>
  <g transform="translate(0.000000, 0.000000)" id="Path" stroke="#000000">
    <path
      d="M0,1.5 C0,2.32842712 0.671572875,3 1.5,3 C2.32842712,3 3,2.32842712 3,1.5 C3,0.671572875 2.32842712,-2.66453526e-16 1.5,-2.66453526e-16 C0.671572875,-2.66453526e-16 0,0.671572875 0,1.5 L0,1.5 Z"
    ></path>
    <path
      d="M0,6.9 C0,7.72842712 0.671572875,8.4 1.5,8.4 C2.32842712,8.4 3,7.72842712 3,6.9 C3,6.36410162 2.71410162,5.86891109 2.25,5.60096189 C1.78589838,5.3330127 1.21410162,5.3330127 0.75,5.60096189 C0.285898385,5.86891109 0,6.36410162 0,6.9 L0,6.9 Z"
    ></path>
    <path
      d="M0,12.3 C0,13.1284271 0.671572875,13.8 1.5,13.8 C2.32842712,13.8 3,13.1284271 3,12.3 C3,11.4715729 2.32842712,10.8 1.5,10.8 C0.671572875,10.8 0,11.4715729 0,12.3 L0,12.3 Z"
    ></path>
  </g>
</template>
;
