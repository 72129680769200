
import { Vue, Options } from 'vue-class-component';
import ThumbModalLoader from '@/components/global/ThumbModal/ThumbModalLoader.vue';

@Options({
    props: {
        title: {
            type: String,
            required: false,
        },
        isThumb: {
            type: Boolean,
            required: false,
            default: false,
        },
        displayLoader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ThumbModalLoader
    },
    emits: ['close'],
})
export default class  BaseDialog extends Vue {
  isThumb!: boolean;
}
