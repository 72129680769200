import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", {
      class: "accordion-trigger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOpen()))
    }, [
      _renderSlot(_ctx.$slots, "accordion-trigger")
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.open)
        ? _renderSlot(_ctx.$slots, "accordion-content", { key: 0 })
        : _createCommentVNode("", true)
    ])
  ]))
}