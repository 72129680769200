
import { Options, Vue } from 'vue-class-component';
import BaseAccordion, {
    itemProps,
} from '@/components/global/UI/BaseAccordion.vue';
import ContentMenu from '@/components/global/ContentMenu/ContentMenu.vue';
import ContentMenuItem from '@/components/global/ContentMenu/ContentMenuItem.vue';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import PopOutMenu from '@/components/global/PopOutMenu/PopOutMenu.vue';

@Options({
    components: {
        BaseIcon,
        BaseAccordion,
        ContentMenu,
        ContentMenuItem,
        PopOutMenu,
    },
    emits: ['close','reset-menu-toggle'],
    props: {
        viewBoxDynamic: {
            type: String,
            required: false,
            default: '-1 -2 24 24',
        },
        widthDynamic: {
            type: String,
            required: false,
            default: '28',
        },
        heightDynamic: {
            type: String,
            required: false,
            default: '28',
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
})
export default class MenuComponent extends Vue {
  isPanelOpen = true;
  viewBoxDynamic!: string;
  widthDynamic!: string;
  heightDynamic!: string;
  searchActive = false;
  collectionActive = false;
  assistantActive = false;
  textMatchActive = false;
  accountActive = false;

  imageTools: Array<itemProps> = [
      {
          type: 'text',
          text: 'Image Editor',
          link: '/archive/',
          icon: 'folder',
          iconTag: 'icon-folder',
      },
      {
          type: 'text',
          text: 'Convert Images',
          link: '/archive/',
          icon: 'folder',
          iconTag: 'icon-folder',
      },
      {
          type: 'text',
          text: 'Remove Background',
          link: '/archive/',
          icon: 'folder',
          iconTag: 'icon-folder',
      },
      {
          type: 'text',
          text: 'WordPress Plugin',
          link: '/archive/',
          icon: 'folder',
          iconTag: 'icon-folder',
      },
  ];

  beforeMount(): void {
      this.getInitialActive();
  }

  closeSidebarPanel(): void {
      this.isPanelOpen = true;
  }

  getInitialActive(): void {
      if (document.URL.indexOf('search') != -1) {
          this.searchActive = true;
      } else if (document.URL.indexOf('collections') != -1) {
          this.collectionActive = true;
      } else if (document.URL.indexOf('assistant') != -1) {
          this.assistantActive = true;
      } else if (document.URL.indexOf('text-match') != -1) {
          this.textMatchActive = true;
      } else if (document.URL.indexOf('account') != -1) {
          this.accountActive = true;
      }
  }

  makeActive(button: string): void {
      this.searchActive = false;
      this.collectionActive = false;
      this.assistantActive = false;
      this.textMatchActive = false;
      this.accountActive = false;
      if (button == 'search') {
          this.searchActive = true;
      } else if (button == 'collection') {
          this.collectionActive = true;
      } else if (button == 'assistant') {
          this.assistantActive = true;
      } else if (button == 'textMatch') {
          this.textMatchActive = true;
      } else if (button == 'account') {
          this.accountActive = true;
      }
      this.$emit('close');
  }

  resetToggle(): void {
      this.$emit('reset-menu-toggle');
  }
}
