<template>
  <path
    d="M0.5 0.499L20.5 20.499"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
  <path
    d="M20.5 0.499L0.5 20.499"
    fill="none"
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></path>
</template>
;
