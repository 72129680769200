import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icon/png/20/search.png'


const _withScopeId = n => (_pushScopeId("data-v-92d10d00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "search-field-container" }
const _hoisted_3 = {
  ref: "inputContainer",
  class: "input-container"
}
const _hoisted_4 = { class: "filter-icon-wrapper" }
const _hoisted_5 = { class: "container-element element-icons" }
const _hoisted_6 = { class: "element-icon-search" }
const _hoisted_7 = {
  key: 0,
  class: "element-icon-reset"
}
const _hoisted_8 = {
  key: 0,
  ref: "searchSuggestion",
  class: "search-suggestion"
}
const _hoisted_9 = ["tabindex", "onClick"]

export function render(_ctx: any,_cache: any) {
  const _component_icon_editor = _resolveComponent("icon-editor")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_icon_close = _resolveComponent("icon-close")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "filter-toggle",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFilters && _ctx.toggleFilters(...args)))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_base_icon, {
                  viewBox: "-1 -3 24 24",
                  width: "26",
                  height: "26",
                  "icon-name": "editor",
                  "stroke-width": "1.4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_editor)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", {
              class: "container-element element-input",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.inputFocus && _ctx.inputFocus(...args)))
            }, [
              _withDirectives(_createElementVNode("input", {
                ref: "searchInput",
                class: "searchfield",
                name: "search",
                placeholder: "Search here...",
                type: "text",
                onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.searchClick && _ctx.searchClick(...args)), ["enter"])),
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.enableReset($event.target.value))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputValue) = $event))
              }, null, 544), [
                [_vModelText, _ctx.inputValue]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Search",
                  title: "Search",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.searchClick()))
                })
              ]),
              (_ctx.toggleReset)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_base_icon, {
                      height: "15",
                      "icon-name": "close",
                      strokeWidth: 1.5,
                      viewBox: "-1 -1 24 24",
                      width: "15",
                      onClick: _ctx.resetInput
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_close)
                      ]),
                      _: 1
                    }, 8, ["strokeWidth", "onClick"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 512),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.toggleSuggestion)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autoComplete, (keyword, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: keyword }, [
                        _createElementVNode("span", {
                          tabindex: i,
                          onClick: ($event: any) => (_ctx.changeKeywordAndSearch(keyword))
                        }, _toDisplayString(keyword), 9, _hoisted_9)
                      ]))
                    }), 128))
                  ], 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}