import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a20a4a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_dots_horizontal = _resolveComponent("icon-dots-horizontal")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_button, {
      responsive: "",
      name: "actions"
    }, {
      icon: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_icon, {
            viewBox: "0 -3 24 24",
            "icon-name": "dots-horizontal",
            width: 23,
            height: 23,
            "stroke-width": 1.4
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_dots_horizontal)
            ]),
            _: 1
          }, 8, ["stroke-width"])
        ])
      ]),
      _: 1
    })
  ]))
}