import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af4986e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "selected-search-filters-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_filter_label = _resolveComponent("selected-filter-label")!
  const _component_selected_filter_clear = _resolveComponent("selected-filter-clear")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.media_types, (media) => {
          return (_openBlock(), _createElementBlock("span", { key: media }, [
            (media.enabled)
              ? (_openBlock(), _createBlock(_component_selected_filter_label, {
                  key: 0,
                  name: media.display,
                  onClick: ($event: any) => (media.enabled = false)
                }, null, 8, ["name", "onClick"]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.orientations, (orientation) => {
          return (_openBlock(), _createElementBlock("span", { key: orientation }, [
            (orientation.enabled)
              ? (_openBlock(), _createBlock(_component_selected_filter_label, {
                  key: 0,
                  name: orientation.name,
                  onClick: ($event: any) => (orientation.enabled = false)
                }, null, 8, ["name", "onClick"]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_ctx.filters.safesearch.value)
          ? (_openBlock(), _createBlock(_component_selected_filter_label, {
              key: 0,
              name: "Safe search on",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters.safesearch.value = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.filters.people.value !== '')
          ? (_openBlock(), _createBlock(_component_selected_filter_label, {
              key: 1,
              name: 
        _ctx.filters.people.value[0].toUpperCase() +
          _ctx.filters.people.value.substring(1) +
          ' people'
      ,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.people.value = ''))
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        (_ctx.filters.color.enabled)
          ? (_openBlock(), _createBlock(_component_selected_filter_label, {
              key: 2,
              colorHint: 'background-color: ' + _ctx.filters.color.toHex(),
              name: _ctx.filters.color.toHex(),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.color.enabled = false))
            }, null, 8, ["colorHint", "name"]))
          : _createCommentVNode("", true),
        (_ctx.filters.dimensions.enabled)
          ? (_openBlock(), _createBlock(_component_selected_filter_label, {
              key: 3,
              name: 
        _ctx.filters.dimensions.width_max +
          ' x ' +
          _ctx.filters.dimensions.height_max
      ,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters.dimensions.enabled = false))
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        (_ctx.filters.duration.enabled)
          ? (_openBlock(), _createBlock(_component_selected_filter_label, {
              key: 4,
              name: 
        _ctx.filters.duration.duration_min +
          ' - ' +
          _ctx.filters.duration.duration_max +
          ' min'
      ,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filters.dimensions.enabled = false))
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createVNode(_component_selected_filter_clear)
      ]))
    : _createCommentVNode("", true)
}