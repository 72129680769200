
import { Vue, Options } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { RootState } from '@/store/types';
import BaseIcon from '@/components/global/UI/BaseIcon.vue';
import SelectedFilterLabel from './SelectedFilterLabel.vue';
import SelectedFilterClear from './SelectedFilterClear.vue';
import { Filters } from '@/store/modules/filters/media_filters';

@Options({
    components: {
        BaseIcon,
        SelectedFilterLabel,
        SelectedFilterClear
    },
})
export default class SearchFiltersSelected extends Vue {
  store!: Store<RootState>;
  filters!: Filters;
  isMounted = false;

  mounted(): void {
      this.store = useStore();
      this.filters = this.store.getters.filters;
      this.filters.dimensions.width_min;
      this.filters.duration.duration_max;
      this.isMounted = true;
  }
}
