<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :title="title"
         :width="width"
         :height="height"
         :viewBox="viewBox"
         :aria-labelledby="iconName"
         :stroke-width=strokeWidth
         :fill="fillColor"
    >
        <title :id="iconName" lang="en">{{ title }}</title>
        <g stroke="none" stroke-width="{{ strokeWidth }}" fill="{{ fillColor }}" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <slot />
        </g>
  </svg>
</template>
<script>
export default {
    props: {
        viewBox: { type: String, default: '0 0 24 24'},
        title: { type: String, default: ''},
        iconName: { type: String, default: ''},
        width: { type: [Number, String], default: 22 },
        height: {type: [Number, String], default: 22 },
        fillColor: {type: String, default: 'none'},
        strokeWidth: { type: [Number, String], default: 1}
    }
};
</script>